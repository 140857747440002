import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
// import ReadPdf from '../ReadPdf/ReadPdf'
const PdfPopUp = ({ isOpen, toggle, file }) => {
    return (
        <>
            <Modal
                isOpen={isOpen}
                toggle={toggle}
                fullscreen
                centered

            >
                <ModalHeader toggle={toggle} className='text-primary text-center'>
                    <span className='d-block text-center'>Read Document </span>
                </ModalHeader>
                <ModalBody className='text-center'>
                    <iframe src={file}
                        className='pdf-rendered'></iframe>
                </ModalBody>
            </Modal>
        </>
    )
}

export default PdfPopUp
