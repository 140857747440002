import React, { useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ReactComponent as IncashLogo } from './../../assets/images/referYourEmployer/Incash-refer-logo.svg';
import { ReactComponent as FooterLogo } from './../../assets/images/referYourEmployer/logo-footer.svg';
import { ReactComponent as Arrow } from './../../assets/images/referYourEmployer/arrow.svg';
import Devices from './../../assets/images/referYourEmployer/devices.png';
import HeroImage from './../../assets/images/referYourEmployer/refer-hero.png';
import { useAlert } from 'react-alert';
import './referEmployeer.scss';
import { addReferYourEmployer } from '../../API/Services/authService';
import { useForm } from 'react-hook-form';
import LoaderWrapper from '../../Components/LoaderWrapper/LoaderWrapper';
import { RouterConstant } from '../../constants/RouteConstant';

export default function ReferYourEmployer() {
    const myRef = useRef(null)
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
    } = useForm();
    let navigate = useNavigate()
    const alert = useAlert();
    const [loader, setLoader] = useState(false);
    const handleReferYourEmployer = (formData) => {
        setLoader(true)
        let data = {
            "companyName": formData?.companyName,
            "managerName": formData?.managerName,
            "managerEmail": formData?.managerEmail,
            "managerPhoneNumber": formData?.managerPhoneNumber,
            "companySize": formData?.companySize
        }
        addReferYourEmployer(data)
            .then(response => {
                setLoader(false)
                if (response?.data?.status === 'OK') {
                    navigate(RouterConstant?.referEmployerThanku)
                    alert.success(response?.data?.message);
                }
                else {
                    console.log(response)
                }
            })
            .catch(error => {
                console.log(error)
                setLoader(false)
                alert.error(error?.message)
            })
    }

    const executeScroll = () => myRef.current.scrollIntoView()    
    return (
        <div className='refer-page'>
            <div className='container-full-width'>
                <div className='container container-custom-xl'>
                    {/* <img src={}/> */}
                    <div className='header-refer-employer'>
                        <IncashLogo className="incash-logo" />
                    </div>
                </div>
                <div className='container container-custom-xl'>
                    <div className='row align-items-center'>
                        <div className='col-lg-7 order-2 order-lg-1'>
                            <div className='hero-content'>
                                <h1 className='hero-heading'>Say No to High Interest Loans,
                                    Take control of your finances<span className='dot'>.</span></h1>
                                <div className='btn-wrapper-refer'>
                                    <button  onClick={executeScroll} className='btn btn-primary'>Get Started</button>
                                    <a target='_blank' href='https://incash.africa/Employer.html' className='link-learn-more'>
                                        <span className=''>Learn More</span> <Arrow className='arrow-icon' /></a>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-5 order-1 order-lg-2'>
                            <div className='hero-image-wrapper'>
                                <img src={HeroImage} className='hero-image' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='devices-container-wrapper'>
                <div className='container container-custom-xl'>
                    <div className='inner-color-container'>
                        <div className='heading-second-section'>
                            <p className='heading-content'>It's time to break free and regain control of your financial well-being. Access your hard-earned wages anytime of the month before payday.</p>
                        </div>
                        <div className='devices-wrapper'>
                            <img src={Devices} className='devices-image' />
                        </div>
                    </div>
                </div>
            </div>

            <div className='form-container-refer' id='referForm' ref={myRef}>
                <div className='container container-custom-xl'>
                    <div className='row'>
                        <div className='col-lg-10 mx-auto'>
                            <div className='form-wrapper-inner'>

                                <h2 className='form-heading'>Refer your Employer<span className='dot'>.</span></h2>

                                <form className='form-wrapper' onSubmit={handleSubmit(handleReferYourEmployer)}>
                                    <div className='form-group'>
                                        <label htmlFor="companyName" className="form-label">Company Name</label>
                                        <input
                                            type="text"
                                            className={`form-control input-box ${errors.companyName ? "not-valid" : ""}`}
                                            id="companyName"
                                            placeholder="Enter company name"
                                            {...register('companyName', { required: true })}
                                        />
                                        {errors.companyName && errors.companyName.type === "required" && <small className="error-msg">Please enter company name</small>}
                                    </div>
                                    <div className='form-group'>
                                        <label htmlFor="managerName" className="form-label">Manager’s Name</label>
                                        <input
                                            type="text"
                                            className={`form-control input-box ${errors.managerName ? "not-valid" : ""}`}
                                            id="managerName"
                                            placeholder="Enter manager’s name"
                                            {...register('managerName', { required: true })}
                                        />
                                        {errors.managerName && errors.managerName.type === "required" && <small className="error-msg">Please manager's name</small>}
                                    </div>

                                    <div className='form-group'>
                                        <label htmlFor="managerEmail" className="form-label">Manager’s Email Address</label>
                                        <input
                                            type="email"
                                            className={`form-control input-box ${errors.managerEmail ? "not-valid" : ""}`}
                                            id="managerEmail"
                                            placeholder="Enter manager’s email address"
                                            {...register('managerEmail',
                                                { required: true })}
                                        />
                                        {errors.managerEmail && errors.managerEmail.type === "required" && <small className="error-msg">Please enter manager's email</small>}
                                    </div>
                                    <div className='form-group'>
                                        <label htmlFor="managerContact" className="form-label">Manager’s Phone Number</label>
                                        <input
                                            type="text"
                                            className={`form-control input-box ${errors.managerPhoneNumber ? "not-valid" : ""}`}
                                            id="managerContact"
                                            placeholder="Enter manager’s phone number"
                                            {...register('managerPhoneNumber',
                                                {
                                                    required: true,
                                                    minLength: 10, maxLength: 11
                                                })}
                                        />
                                        {errors.managerPhoneNumber && errors.managerPhoneNumber.type === "required" && <small className="error-msg">Please enter manager's contact number </small>}
                                    </div>
                                    <div className='form-group'>
                                        <label htmlFor="formControlInput" className="form-label">Size of Company</label>
                                        <select
                                            class={`form-control input-box ${errors.companySize ? "not-valid" : ""}`}
                                            aria-label="Default select"
                                            {...register('companySize', { required: true })}
                                        >
                                            <option value="" selected disabled>Select company size</option>
                                            <option value="1-20">1-20</option>
                                            <option value="20-50">20-50</option>
                                            <option value="50-100">50-100</option>
                                            <option value="100+">100+</option>
                                        </select>
                                        {errors.companySize && errors.companySize.type === "required" && <small className="error-msg">Please select company size</small>}
                                    </div>

                                    <div className='submit-btn-container d-flex'>

                                        <button className='btn btn-primary mx-auto w-auto'>Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='refer-footer'>
                <div className='container container-custom-xl'>
                    <div className='row justify-content-between'>
                        <div className='col-lg-5'>
                            <FooterLogo className='footer-logo' />
                            <p className='footer-text'>Incash is a fintech company that aims to revolutionize the delivery of financial services. We help employers to strategically manage their funds and ensure that all the employees get their right of financial freedom.</p>
                            <p className='footer-text copy-right d-none d-md-block'>© 2023 Incash Inc. All Rights Reserved</p>
                        </div>
                        <div className='col-lg-4 align-self-end'>
                            <div className='refer-social'>
                                <h3 className='Social'>Socials</h3>
                                <ul className='social-items list-unstyled'>
                                    <li className='social-link-wrapper'>
                                        <a target='_blank' href='https://www.linkedin.com/company/in-cash/' className='social-link'>LinkedIn</a>
                                    </li>
                                    <li className='social-link-wrapper'>
                                        <a target='_blank' href='https://youtube.com/@Incashafrica' className='social-link'>Youtube</a>
                                    </li>
                                    <li className='social-link-wrapper'>
                                        <a target='_blank' href='https://www.instagram.com/incashafrica/' className='social-link'>Instagram</a>
                                    </li>
                                    <li className='social-link-wrapper'>
                                        <a target='_blank' href='https://www.facebook.com/incash.africa/' className='social-link'>Facebook</a>
                                    </li>
                                    <li className='social-link-wrapper'>
                                        <a target='_blank' href='https://twitter.com/incashafrica?s=21&t=AbmpdOgXgcFkqUz9dupCTQ' className='social-link'>Twitter</a>
                                    </li>
                                </ul>
                            </div>

                            <p className='footer-text copy-right d-block d-md-none'>© 2023 Incash Inc. All Rights Reserved</p>
                        </div>
                    </div>
                </div>
            </div>
            {
                loader ?
                    <LoaderWrapper /> :
                    ''
            }
        </div>
    )
}
