import ApiInstance from "../../config/intercepter"
import Api from "./../../config/api";
import { setHeaderType } from "../../helper/helper";

// export function transactionList(payload) {
//     // setHeaderType("application/json")
//     return ApiInstance.get(`${Api.transactionList}`)
// }
// export function submitRequest(payload) {
//     setHeaderType("application/json")
//     return ApiInstance.post(`${Api.submitRequest}`, payload)
// }

export function transactionList(pageType, sort, pageNumber, limit, searchString) {
    setHeaderType("application/json")
    return ApiInstance.get(`${Api.transactionList}?pageType=${pageType}&sortKey=${sort?.keyName}&sortOrder=${sort?.order}&page=${pageNumber}&limit=${limit}&searchString=${searchString}`);
}
