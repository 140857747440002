import React, { useEffect, useState } from 'react'
import './header.scss'
import { Link } from 'react-router-dom'
import { ReactComponent as MenuIcon } from '../assets/images/icons/menu_icon.svg'
import { RouterConstant } from '../constants/RouteConstant'
import { getUserDetails } from '../API/Services/authService'
import LoaderWrapper from '../Components/LoaderWrapper/LoaderWrapper'
import { ReactComponent as WhatsappIcon } from '../assets/images/icons/whatsapp-icon.svg'
import UserIcon from '../assets/images/user.png';
const Header = ({ toggle, toggleSidebar }) => {
    const [loader, setLoader] = useState(false);
    const [user, setUser] = useState({})
    const [profilePercentage, setProfilePercentage] = useState(0)


    useEffect(() => {
        setLoader(true)
        getUserDetails()
            .then((response) => {
                setLoader(false)
                if (response?.data?.status === 'OK') {
                    setUser(response?.data?.data?.user);
                    const { employeeId, workEmail } = response?.data?.data?.user?.workDetails;
                    const fieldStatus = {...response?.data?.data?.user.fieldStatus};
                    if (!employeeId || employeeId == 'undefined') {
                        delete (fieldStatus.employeeId);
                    }
                    if (!workEmail || workEmail == 'undefined') {
                        delete (fieldStatus.workEmail);
                    }
                    let tempPercentage = Object.values(fieldStatus)
                    let counts = {}
                    for (const num of tempPercentage) {
                        counts[num] = counts[num] ? counts[num] + 1 : 1;
                    }
                    counts[2] = counts[2] ? counts[2] : 0
                    setProfilePercentage(((counts[2] / tempPercentage.length) * 100).toFixed(0))
                }
                else {
                    console.log('Error response-->', response)
                }
            })
            .catch((error) => {
                setLoader(false)
                console.log('Error response-->', error)
            })
    }, [])

    return (
        <>
            {loader ? <LoaderWrapper /> :
                <header className="header-wrapper">
                    <div className="menu-icon-wrapper" onClick={toggleSidebar}>
                        <MenuIcon />
                    </div>
                    <div className="heading">
                        <p className="heading-text-two">{user.firstName} {user.lastName}</p>
                        <label className="company-name">{user.companyName != "undefined" ? user.companyName : 'No Company Name'}</label>
                    </div>
                    <div className="header-icon-wrapper">
                        {profilePercentage > 99 ? '' : <div className='header-incomplete'>
                            <h4 className="incomplete-percent">Your profile is {profilePercentage}% complete </h4>
                            {
                                profilePercentage !== 100 &&
                                <Link className="btn warning-btn ms-3 mt-0" to={RouterConstant.FillProfile}>Upload Documents</Link>
                            }
                        </div>}
                        
                    </div>
                </header>
            }
        </>
    )
}

export default Header
