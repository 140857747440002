import React from 'react';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { loginApiFun } from '../API/Services/authService';
import { RouterConstant } from '../constants/RouteConstant';
import { Controller, useForm } from 'react-hook-form';
import { ReactComponent as Logo } from '../assets/images/logo.svg';
import { ReactComponent as DropdownIcon } from '../assets/images/icons/dropdown_icon.svg'
import './auth.scss';
import { setToken, setUserId } from '../helper/helper';
import LoaderWrapper from '../Components/LoaderWrapper/LoaderWrapper';
import { useAlert } from 'react-alert';
import OtpInput from 'react-otp-input';
import authBg from '../assets/images/background/auth-bg.png';


const Login = () => {
    let navigate = useNavigate()
    const alert = useAlert();
    const [loader, setLoader] = useState(false);

    const [errorNumber, setErrorNumber] = useState(false);
    const [errorPassword, setErrorPassword] = useState(false);

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm();

    const handleLogin = (formData) => {
        let newPhone = formData.phn
        if (newPhone.length > 10) {
            newPhone = newPhone.substring(1)
        }
        setLoader(true)
        let data = {
            "mobile": newPhone,
            "password": formData.password
        }
        loginApiFun(data)
            .then((response) => {
                setLoader(false)
                if (response?.data?.status === 'OK') {
                    if (response?.data?.data?.basicDetailsStatus === 0) {
                        localStorage.setItem('tempToken', response?.data?.data?.passwordSecret)
                        setUserId(response?.data?.data?.userId)
                        navigate(RouterConstant.AddUserDetails);
                        alert.success("Please add your basic details")
                    } else {
                        setToken(response?.data?.data?.token)
                        setUserId(response?.data?.data?.userId)
                        alert.success(response?.data?.message)
                        navigate(RouterConstant.Layout);
                    }

                } else {
                    console.log("test login api", response)
                }
            })
            .catch(error => {
                console.log("catch block", error)
                setLoader(false)
                alert.error(error?.message)
                if (error?.message === "Invalid password!")
                    setErrorPassword(true)
                //   setMessage(error.data.message);
                else if (error?.message === "No Users Exist")
                    setErrorNumber(true)
                else {
                    console.log(error?.message)
                }
            })
    }

    return (
        <>
            <div className="auth-wrapper" style={{backgroundImage: `url(${authBg})`}}>
                <div className="auth-inner-wrapper">
                    <div className="auth-header">
                        <div className="logo-wrapper">
                            <Logo className="logo-icon" />
                        </div>
                        {/* <h2 className="auth-heading text-center">
                            Access your salary anytime, anywhere
                        </h2>
                        <p className="auth-sub-heading-1 text-center">Welcome Back</p>
                        <p className="auth-sub-heading-2 text-center">Sign In</p> */}
                    </div>

                    <form className="form-wrapper d-flex flex-column align-items-center" onSubmit={handleSubmit(handleLogin)}>
                        
                        
                        <div className="input-wrapper w-100">
                            <label>Phone Number</label>
                            <div className='position-relative'>
                                <div className="phn-details-placeholder">
                                    {/* <NumberIcon /> */}
                                    <i className="fa fa-phone" aria-hidden="true"></i>
                                    <p>+234</p>
                                    <DropdownIcon />
                                </div>
                                <input type="number" className={`form-control phn-field input-box ${errors.phn ? "not-valid" : ""}`}
                                    {...register('phn', { required: true, minLength: 10, maxLength: 11 })}
                                    id="number" placeholder="Phone Number"
                                />
                            </div>
                            {/* {errors.phn && errors.phn.type === "required" && <small className="error-msg">Please enter all the details inorder to Login</small>} */}
                            {errors.phn && (errors.phn.type === "minLength" || errors.phn.type === "maxLength") && <small className="error-msg">Please enter a correct phone number</small>}
                        </div>

                        <div className="input-wrapper text-center">
                            <label className="input-label  d-block text-center fw-bold text-uppercase" htmlFor="bank-name">Confirm Pin</label>
                            <Controller
                                render={({ field: { onChange, value } }) => (
                                    <OtpInput
                                        value={value}
                                        onChange={onChange}
                                        containerStyle="input-otp-wrapper"
                                        inputStyle={`input-otp ${errors.password ? "not-valid" : ""}`}
                                        focusStyle="focus-style"
                                        numInputs="4"
                                        shouldAutoFocus="true"
                                        isInputNum={true}
                                        isInputSecure={true}
                                    />
                                )}
                                control={control}
                                name="password"
                                rules={{
                                    required: true,

                                }}
                            />
                            {((errors.password && errors.password.type === "required") || (errors.phn && errors.phn.type === "required")) && <small className="error-msg">Please enter all the details inorder to Login</small>}
                            {errorNumber && <small className="error-msg">It seems you are not registered with us, Would you like to
                                Signup?</small>}
                            {errorPassword && <small className="error-msg">You have entered a wrong
                                Pin</small>}
                        
                            <p className="text-wrapper heighlight">
                                <Link to={RouterConstant.ForgotPassword}>Forgot Pin?</Link>
                            </p>
                        </div>

                        <div className="text-center submit w-100">
                            <button type="submit" className="btn primary-btn w-100">Login</button>
                        </div>
                        <p className="text-md-center auth-navigation">
                            Don't have an account? <Link to={RouterConstant.Signup} className="heighlight">Sign Up</Link>
                        </p>
                    </form>
                </div>
            </div>
            {
                loader ?
                    <LoaderWrapper /> :
                    ''
            }
        </>
    )
}

export default Login
