import React, { useEffect, useState } from 'react'
import { getUserDetails } from '../../API/Services/authService';
import LoaderWrapper from '../../Components/LoaderWrapper/LoaderWrapper';
import BankDetails from './BankDetails';
import BasicDetails from './BasicDetails';
import KycDetails from './KycDetails';
import {ReactComponent as UserIcon} from './../../assets/images/icons/user_icon.svg'
import './profile.scss'
import WorkDetails from './WorkDetails';
import MyBadge from '../../Components/Common/MyBadge';
import BasicDetailsBadge from '../../Components/Common/BasicDetailsBadge';
import WorkDetailsBadge from '../../Components/Common/WorkDetailsBadge';
import BankDetailsBadge from '../../Components/Common/BankDetailsBadge';
import KYCDetailsBadge from '../../Components/Common/KYCDetailsBadge';
function FillProfile() {
    const [loader, setLoader] = useState(false);
    const [user, setUser] = useState([])
    useEffect(() => {
        getUser()
    }, [])


    const getUser = (formName) => {
        setLoader(true)
        getUserDetails()
            .then((response) => {
                setLoader(false)
                if (response?.data?.status === 'OK') {
                    setUser(response?.data?.data?.user)
                }
                else {
                    console.log('Error response-->', response)
                }
            })
            .catch((error) => {
                setLoader(false)
                console.log('Error response-->', error)
            })
    }


    const [isOpenToast, setIsOpenToast] = useState(false)


    // const setToast = (value) => {
    //     setIsOpenToast(value)
    //     setTimeout(() => { setIsOpenToast(false) }, 3000)
    // }



    return (
        <div className="profile-wrapper">
            {
                loader ? <LoaderWrapper /> :

                    <>
                        {/* Basic Details */}
                        <div className='profile-detail'>
                            <div className='profile-image-wrapper'>
                                {user.profilePicture ? <img className='profile-image' src={user.profilePicture} /> : <UserIcon className='profile-image' />}
                            </div>
                            <div className='profile-text-wrapper'>
                                <h2 className='heading'>{user.fullName}</h2>
                                <p className='company'>{user.companyName != 'undefined' ? user.companyName : 'No Company Name'}</p>
                                <label className='user-id'>USER ID: {user.generatedUserId}</label>
                            </div>
                        </div>


                        <div className={`section-heading`}>Basic Details <BasicDetailsBadge user={user} /></div>
                        <div>
                            <div accordionId="1">
                                <BasicDetails user={user} />
                            </div>
                        </div>


                        {/* work details */}
                        <div className={`section-heading`}>Work Details <WorkDetailsBadge user={user} /></div>
                        <div>
                            <div>
                                <WorkDetails
                                    workDetails={user.workDetails}
                                    updateData={getUser}
                                    user={user} />
                            </div>
                        </div>


                        {/* KYC Details */}

                        <div className={`section-heading`}>KYC Details <KYCDetailsBadge user={user} /></div>
                        <div>
                            <div>
                                <KycDetails
                                    kycDetails={user.kycDetails}
                                    updateData={getUser}
                                    user={user}
                                />
                            </div>
                        </div>


                        {/* Bank Details */}

                        <div className={`section-heading`}>Bank Details <BankDetailsBadge user={user} /></div>

                        <BankDetails
                            bankDetails={user.bankDetails}
                            updateData={getUser}
                            user={user}
                        />


                    </>

            }
            <p className='text-center fw-bold text-danger'>For any query please contact us on <a href='mailto:support@incash.africa' target="_blank">support@incash.africa</a></p> :
        </div>
    )
}

export default FillProfile
