import setting from './settings';

export default (() => {
  return {

    // Basic Auth
    'AUTH': setting.api.AUTH,

    // Auth
    signUp: `${setting.api.url}user/signup`,
    otp:`${setting.api.url}user/verify-otp`,
    resendOtp:`${setting.api.url}user/resend-otp`,
    createPwd:`${setting.api.url}user/create-password`,
    changePassword:`${setting.api.url}user/changePassword`,
    login: `${setting.api.url}user/mobile-login`,
    forgotPassword: `${setting.api.url}user/forgetPassword`,
    resetPassword: `${setting.api.url}user/resetPassword`,
    addUserDetails: `${setting.api.url}user/personal-info/add`,
    addBankDetails: `${setting.api.url}user/bankDetails`,
    addKycDetails: `${setting.api.url}user/kycDetails`,
    addWorkDetails: `${setting.api.url}user/workDetails`,
    companyList: `${setting.api.url}user/companyNameList`,

    // upload nin slip
    uploadNinSlip: `${setting.api.url}user/uploadFileToServerNinSlip`,
    uploadValidId: `${setting.api.url}user/uploadFileToServerValidId`,
    uploadUtility: `${setting.api.url}user/uploadFileToServerUtility`,
    // partner
    getUserList: `${setting.api.url}user/getUserList`,
    // get details
    getUserDetails: `${setting.api.url}user/profile`,
    //request amount
    requestAmount: `${setting.api.url}request/processingFeedetails`,
    submitRequest: `${setting.api.url}request/submit`,
    
    //home page and verification api
    checkProfileStatus: `${setting.api.url}user/status`,
    homeDashboard: `${setting.api.url}user/home`,
    withdrawRecent: `${setting.api.url}user/withdrawal/recent`,

    // transaction
    transactionList: `${setting.api.url}user/transactions`,
    getCompanyContractorSite: `${setting.api.url}user/getCompanyContractorSiteUpdated`,
    cancelRequest: `${setting.api.url}user/cancelRequest`,

    // refer your employer
    referEmployer: `${setting.api.url}user/referEmployer`,
  }
})()