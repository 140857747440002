const monthNames = {0: "Jan", 1: "Feb", 2 : "Mar", 3: "Apr", 4: "May", 5: "Jun", 6:"Jul", 7: "Aug", 8: "Sep", 9: "Oct", 10: "Nov", 11: "Dec"};
const maxDateByMonth = {"Jan": 31, "Feb": 28, "Mar" : 31, "Apr": 30, "May": 31, "Jun": 30, "Jul": 31, "Aug": 31, "Sep": 30, "Oct": 31, "Nov": 30, "Dec": 31};

function getCurrentMonthName(date) {
    return monthNames[date.getMonth()];
}

function getNextMonthName(date) {
    return monthNames[(date.getMonth() + 1) % 12];
}

function isLeapYear(year) {
  return ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0);
}

function getMonthMaxDate(date, monthName) {
    if(monthName === "Feb" &&  isLeapYear(date.getFullYear())) {
            return 29;
    } 
    return maxDateByMonth[monthName];
}

export function getDaysList(date) {
    const todayDate = date.getDate();
    const currentMonth = getCurrentMonthName(date);
    const nextMonth = getNextMonthName(date);
    let maxDate = getMonthMaxDate(date, nextMonth);
    let dayList = [];
    for(let index = 1; index <= maxDate; index++) {
        if(index <= todayDate) {
            dayList.push(`${index} of ${nextMonth}`);
        } else {
            dayList.push(`${index} of ${currentMonth}`);
        }
    }
    return dayList;
}


export function getDateFromNo(day, string){
    let currentDate = new Date();
    let currentMonth = currentDate.getMonth();
    let currentDay = currentDate.getDate();
    let dayString = {
        0: "Jan", 1: "Feb", 2 : "Mar", 3: "Apr", 4: "May", 5: "Jun", 6:"Jul", 7: "Aug", 8: "Sep", 9: "Oct", 10: "Nov", 11: "Dec"
    }
    if(day > currentDay){
        if(string){
            let dueDate = " " + day + " of " + string
            return dueDate
        }else{
            let dueDate = " " + day + " of " + dayString[currentMonth]
            return dueDate
        }
    }else{
        if(string){
            let dueDate = " " + day +" of "+ string
            return dueDate
        }else{
            let dueDate = " " + day + " of " + dayString[currentMonth+1]
            return dueDate
        }
        
    }
}