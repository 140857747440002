import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Input } from 'reactstrap';
import { companyNames, dayDate } from '../../constants/StringsConstant';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'

import { addWorkDetailsApiFun, getCompanyContractorSite } from '../../API/Services/authService';
import LoaderWrapper from '../../Components/LoaderWrapper/LoaderWrapper';
import { useAlert } from 'react-alert';
import Dropzone from 'react-dropzone'
import PdfPopUp from '../../Components/Common/PdfPopUp/PdfPopUp';
import { getDaysList } from '../../helper/dateData';
import FieldBadge from '../../Components/Common/badge/FieldBadge';
import { FileUploadIcon } from '../../Components/svg';
import { fieldBadgeClassName } from '../../helper/helper';


const showSaveBtn = (user) => {
    if (user?.fieldStatus) {
        let {employeeId, salary, salarycreditDay, workEmail, bankStatements, contractor, site} = user.fieldStatus;
        if (employeeId == 2 && salary == 2 && salarycreditDay == 2 && workEmail == 2 && bankStatements == 2 && contractor == 2 && site == 2) {
            return ''
        } else if(user?.workDetails?.workEmail == "" || user?.workDetails?.workEmail == "undefined") {
            return <div className="save-btn-wrapper">
                    <button type="submit" className='btn primary-btn w-auto px-5'>Save</button>
                </div>
        }else if(user?.workDetails?.employeeId == "" || user?.workDetails?.employeeId == "undefined") {
            return <div className="save-btn-wrapper">
                    <button type="submit" className='btn primary-btn w-auto px-5'>Save</button>
                </div>
        }else {
            return <div className="save-btn-wrapper">
                    <button type="submit" className='btn primary-btn w-auto px-5'>Save</button>
                </div>
        }
    }

}


function WorkDetails({ workDetails, updateData, user }) {
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
        control,
    } = useForm();
    const [loader, setLoader] = useState(false);
    const alert = useAlert();
    const checkBoxOne = watch("checkBoxOne", false)
    const checkBoxTwo = watch("checkBoxTwo", false)
    const [fileType, setFileType] = useState()
    const [bankStatementImage, setBankStatementImage] = useState("");
    const [bankStatementFile, setBankStatementFile] = useState();
    const [dateOfMonth, setDateOfMonth] = useState([])
    const [isOpen, setIsOpen] = useState(false);
    const [allContractors, setContractorList] = useState([]);
    const [allSites, setSiteLists] = useState([]);

    const handlePdfPopUp = () => {
        setIsOpen(!isOpen)
    }

    useEffect(() => {
        setValue('employeeId', workDetails?.employeeId)
        setValue('salary', workDetails?.salary)
        setValue('email', workDetails?.workEmail)
        setValue('lastDate', workDetails?.salarycreditDay)
        setValue('contractor', workDetails?.contractor)
        setValue('site', workDetails?.site)
        // setValue('bankStatement', workDetails?.bankStatements)
        let fileType = workDetails?.bankStatements?.split(".")?.slice(-1)
        setFileType(fileType)
        setBankStatementImage(workDetails?.bankStatements);

        if (user?.companyName) {
            contractorSites(user?.companyName);
        }
       
    }, [workDetails])
    

    useEffect(() => {
        let date = new Date()
        setDateOfMonth(getDaysList(date))
    }, [])




    const handleBankStatementImage = (e) => {
        if (e.target.files[0]) {
            let mediaType = e.target.files[0]?.type?.split("/")[0]
            setFileType(mediaType);
        }
        const reader = new FileReader()

        reader.addEventListener('load', () => {
            setBankStatementImage(reader.result)
        })

        if (e.target.files[0]) {
            reader.readAsDataURL(e.target.files[0]);
            setBankStatementFile(e.target.files[0])
        }
    }

    const handleWorkDetails = (data) => {
        setLoader(true)
        let formData = new FormData();
        formData.append("employeeId", data.employeeId ? data.employeeId : '')
        formData.append("salary", data.salary)
        formData.append("salarycreditDay", data.lastDate)
        formData.append("workEmail", data.email ? data.email : '')
        formData.append("bankStatements", bankStatementFile)
        formData.append("contractorName", data.contractor ? data.contractor : '')
        formData.append("site", data.site ? data.site : '')

        for (var key of formData.entries()) {
            console.log(key[0] + ', ' + key[1]);
        }

        addWorkDetailsApiFun(formData)
            .then(response => {
                setLoader(false)
                if (response?.data?.status === 'OK') {
                    alert.success(response?.data?.message);
                    updateData('2')
                }
                else {
                    console.log('Error response-->', response)
                }
            })
            .catch(error => {
                console.log(error)
                setLoader(false)
                alert.error(error?.message)
            })
    }


    const allowOnlyNumber=(value)=>{
        return value.replace(/[^0-9]/g, '')
    }
    


    const contractorSites = (param) => {
        setLoader(true);
        getCompanyContractorSite(param).then((response) => {
            setLoader(false);
            if (response?.data?.status) {
                setContractorList(response?.data?.data?.contractors);
                setSiteLists(response?.data?.data?.sites);
            } else {
                console.log("test login api", response)
            }
        })
            .catch(error => {
                setLoader(false);
                console.log("catch block", error)
                alert.error(error?.message);
            })
    }

    return (
        <>
            <div className="fill-details-wrapper">
               
                <div className='row'>
                    <div className='col-xl-8 col-lg-10'>
                        <form className="" onSubmit={handleSubmit(handleWorkDetails)}>
                            <div className='form-wrapper'>
                                <div className="input-wrapper">
                                <label className="input-label-profile" htmlFor="employee-id">Employer Id: <FieldBadge status={user?.fieldStatus?.employeeId} /></label>
                                <input
                                    disabled={(user?.workDetails?.employeeId != '' || user?.workDetails?.employeeId != 'undefined') ? user?.fieldStatus?.employeeId == 2 ? true : false : false}
                                    type="text"
                                    className={`input-box-profile form-control ${errors.employeeId ? "not-valid" : ""} ${fieldBadgeClassName(user?.fieldStatus?.employeeId)}`}
                                    {...register("employeeId")}
                                    id="employee-id"
                                    
                                    placeholder="Employer ID" />
                            
                            </div>

                            <div className="input-wrapper">
                                <label className="input-label-profile" htmlFor="salary">Monthly salary: <FieldBadge status={user?.fieldStatus?.salary} /></label>
                                <input
                                    disabled={user?.fieldStatus?.salary == 2 ? true : false}
                                    type="text"
                                    className={`input-box-profile form-control ${errors.salary ? "not-valid" : ""} ${fieldBadgeClassName(user?.fieldStatus?.salary)}`}
                                    {...register("salary")}
                                    id="salary"
                                    placeholder="Monthly salary" />
                            </div>
                                

                            <div className="input-wrapper">
                                <label className="input-label-profile" htmlFor="last-date">Month Day on which salary is credited: <FieldBadge status={user?.fieldStatus?.salaryDueDate} /></label>
                                <Controller
                                    control={control}
                                    name="lastDate"
                                    render={({ field: { onChange, value } }) => (
                                        <Input
                                            disabled={user?.fieldStatus?.salarycreditDay == 2 ? true : false}
                                            type="select"
                                            className={`form-control input-box-profile ${errors.lastDate ? "not-valid" : ""} ${fieldBadgeClassName(user?.fieldStatus?.salaryDueDate)}`}
                                            value={value}
                                            id="last-date"
                                            onChange={onChange}>
                                            <option value="" selected disabled>Select date</option>
                                            {dayDate?.map((date, index) => <option value={index + 1} key={index}>{date.day}</option>)}
                                        </Input>
                                    )}/>
                                {errors.lastDate && errors.lastDate.type === "required" && <small className="error-msg">Please fill month Day on which salary is credited</small>}
                            </div>
                                

                            <div className="input-wrapper">
                                <label className="input-label-profile" htmlFor="email">Work E-mail Address: <FieldBadge status={user?.fieldStatus?.workEmail} /></label>
                                <input
                                    disabled={(user?.workDetails?.workEmail != '' || user?.workDetails?.workEmail != 'undefined') ? user?.fieldStatus?.workEmail == 2 ? true : false : false}
                                    type="email"
                                    className={`input-box-profile form-control ${errors.email ? "not-valid" : ""} ${fieldBadgeClassName(user?.fieldStatus?.workEmail)}`}
                                    {...register("email")}
                                    id="email"
                                    placeholder="Work E-mail Address" />
                                </div>
                                


                                <div className="input-wrapper">
                                <label className="input-label-profile" htmlFor="last-date">Contractor Name <FieldBadge status={user?.fieldStatus?.contractor} /></label>
                                <Controller
                                    control={control}
                                    name="contractor"
                                    render={({ field: { onChange, value } }) => (
                                        <Input
                                            disabled={user?.fieldStatus?.contractor == 2 ? true : false}
                                            type="select"
                                            className={`form-control input-box-profile ${errors.contractor ? "not-valid" : ""} ${fieldBadgeClassName(user?.fieldStatus?.contractor)}`}
                                            value={value}
                                            id="contractor"
                                            onChange={onChange}>
                                            <option value="" selected disabled>Select contractor</option>
                                            {allContractors?.map((item, index) => <option value={item} key={index}>{item}</option>)}
                                        </Input>
                                    )}/>
                                {errors.contractor && errors.contractor.type === "required" && <small className="error-msg">Please fill month Day on which salary is credited</small>}
                            </div>
                                

                            <div className="input-wrapper">
                                <label className="input-label-profile" htmlFor="last-date">Site <FieldBadge status={user?.fieldStatus?.site} /></label>
                                <Controller
                                    control={control}
                                    name="site"
                                    render={({ field: { onChange, value } }) => (
                                        <Input
                                            disabled={user?.fieldStatus?.site == 2 ? true : false}
                                            type="select"
                                            className={`form-control input-box-profile ${errors.site ? "not-valid" : ""} ${fieldBadgeClassName(user?.fieldStatus?.site)}`}
                                            value={value}
                                            id="site"
                                            onChange={onChange}>
                                            <option value="" selected disabled>Select Site</option>
                                            {allSites?.map((item, index) => <option value={item} key={index}>{item}</option>)}
                                        </Input>
                                    )}/>
                                {errors.site && errors.site.type === "required" && <small className="error-msg">Please fill month Day on which salary is credited</small>}
                            </div>
                                


                            <div className="input-wrapper">
                                <label className="input-label-profile" htmlFor="bankStatement">Upload Bank Statement: <FieldBadge status={user?.fieldStatus?.bankStatements} /></label>
                                {
                                    user?.fieldStatus?.bankStatements == 2 ? '' :
                                        <label htmlFor="bankStatement" className={`input-box-profile form-control label-wrapper  ${errors.bankStatement ? "not-valid" : ""}`}>
                                                <div className=''>
                                                <span className="mt-2">Upload File <FileUploadIcon /></span>
                                            </div>
                                        </label>
                                    
                                    }
                                    
                                    {
                                        user?.fieldStatus?.bankStatements == 2 ? '' : <input  type="file" className={`input-box-profile form-control d-none ${errors.bankStatement ? "not-valid" : ""}`}
                                        {...register("bankStatement")}
                                        id="bankStatement" placeholder="Upload Bank Statement"
                                        onChange={handleBankStatementImage}
                                    />
                                    }
                                

                                {(workDetails?.bankStatement || bankStatementImage) ?
                                    <div className='input-box-profile form-control label-wrapper cursor-pointer mt-3' onClick={handlePdfPopUp}>
                                        <span className='' >
                                            View Bank Statement <i class="fa fa-check text-success" aria-hidden="true" />
                                        </span>
                                    </div>
                                    :
                                    ''
                                }
                            </div>
                            </div>

                            {user ? showSaveBtn(user) : ''}
                            
                        </form>
                    </div>
                </div>
            </div>
            {
                loader ?
                    <LoaderWrapper /> :
                    ''
            }
            <PdfPopUp
                isOpen={isOpen}
                toggle={handlePdfPopUp}
                // file={workDetails?.bankStatements}
                file={bankStatementImage ? bankStatementImage : workDetails?.bankStatements}
            />
        </>
    )
}

export default WorkDetails
