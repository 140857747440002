import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as SuccessImage } from '../../assets/images/SuccessImage.svg'
import LoaderWrapper from '../LoaderWrapper/LoaderWrapper';
function SuccessPanel({ msg, linkText, link }) {
    
    let navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const handleSuccess = () => {
        setLoader(true)
        setTimeout(()=>{
            setLoader(false)
            navigate(link)
        }, 500)
    }
    return (
        <>
            {loader ? <LoaderWrapper /> :
                <div className="success-panel-wrapper">
                    <div className="success-panel">
                        <div className="success-img-wrapper">
                            <SuccessImage className="success-img" />
                        </div>
                        <p className="text-center success-msg">{msg}</p>
                        <div className="link-wrapper">
                            <button onClick={handleSuccess} className="btn">{linkText}</button>

                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default SuccessPanel
