import { useEffect, useState } from "react"
import FieldBadge from "./badge/FieldBadge";

const WorkDetailsBadge = ({ user }) => {
    const [status, setStatus] = useState(0);

    useEffect(() => {
        if (user.fieldStatus) {
            let {
                employeeId,
                salary,
                salarycreditDay,
                workEmail,
                bankStatements,
                contractor,
                site
            } = user.fieldStatus;

            // if (user?.workDetails?.workEmail != "" || user?.workDetails?.workEmail != "undefined" || user?.workDetails?.employeeId != "" || user?.workDetails?.employeeId != "undefined") {
            //     if (salary == 3 || salarycreditDay == 3 || bankStatements == 3 || contractor == 3 || site == 3) {
            //         setStatus(3);
            //     } else if (salary == 2 && salarycreditDay == 2 && bankStatements == 2 && contractor == 2 && site == 2) {
            //         setStatus(2)
            //     } else {
            //         setStatus(1)
            //     }
            // } else {
            //     if (employeeId == 3 || salary == 3 || salarycreditDay == 3 || bankStatements == 3 || contractor == 3 || site == 3 || workEmail == 3) {
            //         setStatus(3);
            //     } else if (employeeId == 2 && salary == 2 && salarycreditDay == 2 && bankStatements == 2 && contractor == 2 && site == 2 && workEmail == 2) {
            //         setStatus(2)
            //     } else {
            //         setStatus(1)
            //     } 
            // }



            if (user?.workDetails?.workEmail == "" || user?.workDetails?.workEmail == "undefined") {
                if (employeeId == 3 || salary == 3 || salarycreditDay == 3 || bankStatements == 3 || contractor == 3 || site == 3) {
                    setStatus(3);
                } else if (employeeId == 2 && salary == 2 && salarycreditDay == 2 && bankStatements == 2 && contractor == 2 && site == 2) {
                    setStatus(2)
                } else {
                    setStatus(1)
                }
            } else if (user?.workDetails?.employeeId == "" || user?.workDetails?.employeeId == "undefined") {
                if (workEmail == 3 || salary == 3 || salarycreditDay == 3 || bankStatements == 3 || contractor == 3 || site == 3) {
                    setStatus(3);
                } else if (workEmail == 2 && salary == 2 && salarycreditDay == 2 && bankStatements == 2 && contractor == 2 && site == 2) {
                    setStatus(2)
                } else {
                    setStatus(1)
                }
            } else {
                if (employeeId == 3 || salary == 3 || salarycreditDay == 3 || bankStatements == 3 || contractor == 3 || site == 3 || workEmail == 3) {
                    setStatus(3);
                } else if (employeeId == 2 && salary == 2 && salarycreditDay == 2 && bankStatements == 2 && contractor == 2 && site == 2 && workEmail == 2) {
                    setStatus(2)
                } else {
                    setStatus(1)
                } 
            }

            
        }
    }, [user.fieldStatus])

    return (
        <span>
            <FieldBadge status={status} />
            <span className={`badge ms-3 badge-msg ${status == 3 ? "bg-danger" : status == 1 ? 'bg-secondary' : status == 2 ? "bg-success" : ''}`}>
                {`${status == 3 ? "Rejected" : status == 1 ? ' Pending verification' : status == 2 ? "Accepted" : ''}`}
            </span>
        </span>  
    )
}

export default WorkDetailsBadge;