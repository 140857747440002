import React, { useState } from 'react'
import { useAlert } from 'react-alert';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { submitRequest } from '../../API/Services/requestService'
import { ReactComponent as InformIcon } from '../../assets/images/icons/warning_icon.svg'
import LoaderWrapper from '../../Components/LoaderWrapper/LoaderWrapper';
import { RouterConstant } from '../../constants/RouteConstant';
import { getDateFromNo } from '../../helper/dateData';

const ReviewRequest = ({ toggleReviewRequest, interestAmount, requestedAmount, salaryDueDate }) => {
    const alert = useAlert();
    const [loader, setLoader] = useState(false);
    let dueDate = getDateFromNo(salaryDueDate)
    let navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
    } = useForm();

    const handleReviewRequest = () => {
        setLoader(true)
        let data = {
            "requestedAmount": requestedAmount,
            "interestAmount": interestAmount,
            "salaryDueDate": salaryDueDate
        }
        // console.log(data)
        submitRequest(data)
            .then(response => {
                setLoader(false)
                if (response?.data?.status === 'OK') {
                    alert.success(response?.data?.message)
                    navigate(RouterConstant.Layout)
                }
                else {
                    console.log('Error response-->', response)
                }
            })
            .catch(error => {
                console.log(error)
                setLoader(false)
                alert.error(error?.message)
            })
    }

    return (
        <>
            {
                loader ? <LoaderWrapper /> :

                    <div className="request-wrapper review">
                        <h2 className="request-heading">Review Request</h2>
                        <div className="request-amount-wrapper">
                            <div className="request-amount">
                                <div className="amount-info">
                                    <h2 className="heading">
                                        Requested Amount : 
                                    </h2>
                                    <p className="value">
                                        {`N${requestedAmount}`}
                                    </p>
                                </div>
                                <div className="amount-info">
                                    <h2 className="heading">
                                        Processing Fee : 
                                    </h2>
                                    <p className="value">
                                        {`N${interestAmount}`}
                                    </p>
                                </div>
                                <div className="amount-info">
                                    <h2 className="heading">
                                        Due Date : 
                                    </h2>
                                    <p className="value">
                                        {dueDate}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <form onSubmit={handleSubmit(handleReviewRequest)}>
                            <div className="msg-wrapper">
                                {/* <InformIcon className="msg-icon" /> */}
                                <div className="input-wrapper check-terms text-md-center">
                                    <input type="checkbox" id="checkTerms"
                                        {...register('checkTerms', { required: true })}
                                        name="checkTerms" value="true" />
                                    <label htmlFor="checkTerms"><p className="msg-content">I agree to pay incash <span className="heighlight-black">{`N${requestedAmount + interestAmount}`}</span> by <span className="heighlight-black">{dueDate}</span></p></label>
                                    {errors.checkTerms && errors.checkTerms.type === "required" && <small className="error-msg">{"you need to agree to our T&C inorder to proceed"}</small>}
                                </div>

                            </div>
                            <div className="d-flex justify-content-end mt-4">
                                <button className="btn primary-btn min-padding ms-2" type="submit">
                                    Agree
                                </button>
                                <button className="btn bordered-btn min-padding ms-2" onClick={toggleReviewRequest}>
                                    Cancel
                                </button>
                            </div>
                        </form>
                    </div>
            }
        </>
    )
}

export default ReviewRequest
