import React, { useEffect, useState } from 'react'
import { useAlert } from 'react-alert';
import { useForm } from 'react-hook-form';
import { addKycDetailsApiFun, uploadNinSlip, uploadUidCard, uploadUtility, uploadValidId } from '../../API/Services/authService';
import FieldBadge from '../../Components/Common/badge/FieldBadge';
import PdfPopUp from '../../Components/Common/PdfPopUp/PdfPopUp';
import LoaderWrapper from '../../Components/LoaderWrapper/LoaderWrapper';
import { FileUploadIcon } from '../../Components/svg';
import { fieldBadgeClassName } from '../../helper/helper';


const showSaveBtn = (user) => {
    if (user?.fieldStatus) {
        let { nin, validId } = user.fieldStatus;
        if (nin == 2 && validId == 2) {
            return ''
        } else {
            return <div className="save-btn-wrapper">
                <button type="submit" className='btn primary-btn w-auto px-5'>Save</button>
            </div>
        }
    }
}


function KycDetails({ kycDetails, updateData, user }) {
    const [isOpen, setIsOpen] = useState(false)
    const [viewFile, setViewFile] = useState('')
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm();

    const handlePdfPopUp = (document) => {
        setIsOpen(!isOpen)

        validId ? setViewFile(validId) : setViewFile(kycDetails?.validId)

    }


    useEffect(() => {
        setValue('idNumber', kycDetails?.nin)

        setValidIdImage(kycDetails?.validId)
    }, [kycDetails])

    const alert = useAlert();
    const [loader, setLoader] = useState(false);

    const [uid, setUid] = useState()
    // media states

    // const [utilityState, setUtilityState] = useState(false)
    const [validIdImage, setValidIdImage] = useState()
    const [validId, setValidId] = useState()

    const handleValidId = (e) => {
        const reader = new FileReader()
        reader.addEventListener('load', () => {
            setValidId(reader.result)
        })
        setUid(e.target.files[0])
        if (e.target.files[0]) {
            reader.readAsDataURL(e.target.files[0])
            let formData = new FormData();
            formData.append('validId', e.target.files[0])
            uploadValidId(formData)
                .then((res) => {
                    if (res?.data?.status === "OK") {
                        let fileUrl = res?.data?.data?.file[0]?.Location
                        setValidIdImage(fileUrl)
                        alert.success(res?.data?.message)
                    }
                })
                .catch(err => {
                    console.log('err', err)
                    alert.error(err?.message)
                })
        }
    }


    // handle form data
    const handleKycDetails = (data) => {
        setLoader(true)
        let formData = {
            nin: "" + data.idNumber,
            validId: validIdImage
        }
        if(data?.idNumber || validIdImage){
            addKycDetailsApiFun(formData)
                .then(response => {
                    setLoader(false)
                    if (response?.data?.status === 'OK') {
                        alert.success(response?.data?.message)
                        updateData('3')
                    }
                    else {
                        console.log('Error response-->', response)
                    }
                })
                .catch(error => {
                    console.log(error)
                    setLoader(false)
                    alert.error(error?.message)
                })
        }else{
            alert.error("Please upload valid Id document or enter the NIN number")
            setLoader(false)
        }

    }
    return (
        <>
            <div className="fill-details-wrapper">
                <div className='row'>
                    <div className='col-xl-8 col-lg-10'>
                        <form className="" onSubmit={handleSubmit(handleKycDetails)}>
                            <div className='form-wrapper'>

                                <div className="input-wrapper">
                                    <label className="input-label-profile" htmlFor="identity-number">NIN: <FieldBadge status={user?.fieldStatus?.nin} /></label>
                                    <input
                                        disabled={user?.fieldStatus?.nin == 2 ? true : false}
                                        type="number"
                                        className={`input-box-profile form-control ${errors.idNumber ? "not-valid" : ""} ${fieldBadgeClassName(user?.fieldStatus?.nin)}`}
                                        {...register("idNumber", { required: false, minLength: 11, maxLength: 11 })}
                                        id="identity-number"
                                        placeholder="National Identification Number" />
                                    {errors.idNumber && errors.idNumber.type === "required" && <small className="error-msg">Please enter NIN</small>}
                                    {errors.idNumber && (errors.idNumber.type === "minLength" || errors.idNumber.type === "maxLength") && <small className="error-msg">Please enter a correct NIN</small>}
                                </div>

                                <div className="input-wrapper">
                                    <label className="input-label-profile" htmlFor="ID">Upload Valid ID: <FieldBadge status={user?.fieldStatus?.validId} /></label>

                                    {
                                        user?.fieldStatus?.validId == 2 ? '' :
                                            <label htmlFor="ID" className={`input-box-profile form-control label-wrapper mb-3 ${errors.uId ? "not-valid" : ""} ${fieldBadgeClassName(user?.fieldStatus?.validId)}`}>
                                                <div className=''>
                                                    <span className="mt-2">{validId ? 'Update valid ID' : 'Upload File'} Update valid ID <FileUploadIcon /></span>
                                                </div>
                                            </label>
                                    }
                                    {
                                        user?.fieldStatus?.validId == 2 ? '' :
                                            <input type="file" className={`input-box-profile form-control d-none ${errors.validId ? "not-valid" : ""}`}
                                                {...register('validId', { required: false })}
                                                // validId ? false : true
                                                onChange={handleValidId}
                                                id="ID"
                                                placeholder="Upload valid ID"
                                            />
                                    }

                                    {(kycDetails?.validId || viewFile) ?
                                        <div className='input-box-profile form-control label-wrapper cursor-pointer' onClick={handlePdfPopUp}>
                                            <span className='' >
                                                View Valid Id <i class="fa fa-check text-success" aria-hidden="true" />
                                            </span>
                                        </div>
                                        : ''
                                    }

                                </div>
                            </div>
                            {user ? showSaveBtn(user) : ''}
                        </form>
                    </div>
                </div>
            </div>

            {
                loader ?
                    <LoaderWrapper /> :
                    ''
            }
            <PdfPopUp
                isOpen={isOpen}
                toggle={handlePdfPopUp}
                file={viewFile}
            />
        </>
    )
}

export default KycDetails