import React, { useEffect, useRef, useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { useAlert } from "react-alert";
import LoaderWrapper from '../../Components/LoaderWrapper/LoaderWrapper';
import ReactPaginate from 'react-paginate';
import { ReactComponent as Asc } from '../../assets/images/icons/ace.svg';
import { ReactComponent as Desc } from '../../assets/images/icons/desc.svg';
import _ from "lodash";
import moment from 'moment'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import SearchWrapper from '../../Components/Search';
import { transactionList } from '../../API/Services/transactionService';
import { getDateFromNo } from '../../helper/dateData';
import { RouterConstant } from '../../constants/RouteConstant';
import { CloseMiniIcon } from '../../Components/svg';

const Transaction = () => {
    // let { userId } = useParams();
    const pagination = useRef();
    const alert = useAlert();
    const [loader, setLoader] = useState(false);
    const [apiData, setApiData] = useState([]);
    const [profileType, setProfiteType] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);
    const [limit, setLimit] = useState(10);
    const [searchString, setSearchString] = useState('');
    const delayedQuery = useRef(_.debounce(q => sendQuery(q), 1000)).current;
    const [searchQuery, setSearchQuery] = useState('');
    const [viewRequest, setViewRequest] = useState('');

    

    const [sorting, setSorting] = useState({
        order: -1,
        keyName: 'createdAt',
    });


    useEffect(() => {
        getAllTransactions();
    }, [profileType, sorting, pageNumber, limit, searchQuery])

    const getAllTransactions = () => {
        setLoader(true)
        transactionList(profileType, sorting, pageNumber, limit, searchString)
            .then((response) => {
                if (response?.data?.status === 'OK') {
                    setApiData(response?.data?.data);

                    // alert.success(response?.data?.isError);
                    // navigate(RouterConstant.Layout);

                } else {
                    console.log("test login api", response)
                }
                setLoader(false)
            })
            .catch(error => {
                console.log("catch block", error)
                //   setMessage(error.data.message);
                alert.error(error?.message);
                setLoader(false)
            })

    }

    const handleAdminFilter = (profileType) => {
        setProfiteType(profileType);
        // getAllUserList(profileType)
    }

    const setPage = ({ selected }) => {
        let pageNo = selected + 1
        setPageNumber(pageNo);
        // getStoreValue(pageNo)
    }
    //  
    const handleSorting = (sortKey, sortOrder) => {
        setSorting({
            order: sortOrder,
            keyName: sortKey
        })
    }

    const handleSearch = (event) => {
        setSearchString(event.target.value);
        delayedQuery(event.target.value);
    }
    const sendQuery = query => setSearchQuery(query);


    const handleClose = () => {
        setViewRequest('');
    }


    return (
        <>
            {/* <div className="top-filter-button">
                <div className="row">
                    <div className="col-md-4">
                        <SearchWrapper
                            type='text'
                            name='searchString'
                            className='mb-3 mt-4 mt-md-0'
                            value={searchString}
                            onChange={handleSearch}
                            placeholder="Search..."
                        />
                    </div>
                </div>
            </div> */}


            <Modal
                isOpen={viewRequest?.generatedRequestId}
                toggle={handleClose}
                className="view_request_modal"
                centered>
                <ModalBody>
                    <div className='d-flex justify-content-between align-content-center'>
                        <p>REQUEST ID: { viewRequest.generatedRequestId}</p>
                        <div className='close_mini_btn' onClick={handleClose}><CloseMiniIcon /> CLOSE</div>
                    </div>

                    

                    <div className='row view_request_contain'>
                        <div className='col-md-6'>
                            <p>AMOUNT REQUESTED</p>
                            <h3>{viewRequest.requestedAmount} NGN</h3>
                        </div>
                        <div className='col-md-6'>
                            <p>AMOUNT PROCESSED</p>
                            <h3>500,000 NGN</h3>
                        </div>
                        <div className='col-md-6'>
                            <p>DUE AMOUNT</p>
                            <h3>525,000 NGN</h3>
                        </div>
                        <div className='col-md-6'>
                            <p>ACCOUNT DETAILS</p>
                            <h3>0108011127 <small>(GUARANTY TRUST BANK)</small></h3>
                        </div>
                        <div className='col-md-6'>
                            <p>CREDIT DATE</p>
                            <h3>February 23, 10:10 AM</h3>
                        </div>
                        <div className='col-md-6'>
                            <p>PROCESSING FEE</p>
                            <h3>2,000 NGN</h3>
                        </div>
                        <div className='col-md-6'>
                            <p>DUE DATE</p>
                            <h3>February 23, 10:10 AM</h3>
                        </div>
                        <div className='col-md-6'>
                            <p>REFERENCE ID</p>
                            <h3>1ETDN44C2XY68</h3>
                        </div>
                        <div className='col-md-6'>
                            <p>STATUS</p>
                            <h3 className={`color_${viewRequest.status}`}>{viewRequest.status}</h3>
                        </div>
                        <div className='col-md-6'>
                            <div className='btn_group'>
                                <button className='btn btn-outline-primary'>Dispute</button>
                                <button className='btn btn-outline-primary'>Report Transaction</button>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>


            <div className="generic-wrapper">

                <h3 className="generic-heading">All Transaction</h3>
                <div className="generic-table">

                    <div className="data-table table-responsive">
                        <table className="table table-borderless">
                            <thead>
                                <tr>
                                    <th scope="col">S/N</th>
                                    <th scope="col">REQUEST ID</th>
                                    <th scope="col">AMOUNT REQUESTED</th>
                                    <th scope="col">AMOUNT PROCESSED</th>
                                    <th scope="col">ADJUSTABLE AMOUNT</th>
                                    <th scope="col">CREDIT DATE
                                        {
                                            (sorting['keyName'] === 'createdAt') && (sorting['order'] === 1) ? <Desc className="ascending_icons ms-3" onClick={() => handleSorting('companyName', -1)} />
                                                : <Asc className="ascending_icons ms-3" onClick={() => handleSorting('companyName', 1)} />
                                        }
                                    </th>
                                    <th scope="col">PROCESSING FEE</th>
                                    <th scope="col">
                                        DUE DATE
                                        {
                                            (sorting['keyName'] === 'createdDate') && (sorting['order'] === 1) ? <Desc className="ascending_icons ms-3" onClick={() => handleSorting('createdDate', -1)} />
                                                : <Asc className="ascending_icons ms-3" onClick={() => handleSorting('createdDate', 1)} />
                                        }
                                        
                                        </th>
                                        <th scope="col">Status</th>
                                    <th scope="col">More</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    apiData?.requests?.map((request, index) => {

                                        return <tr key={index}>
                                            <td scope="row" className="pt-md-4 pt-3">{((index + 1) + ((pageNumber - 1) * limit))}</td>
                                            <td className="pt-md-4 pt-3">{(request?.generatedRequestId) ? (request?.generatedRequestId) : '-'}</td>
                                            <td className="pt-md-4 pt-3">{(request?.requestedAmount) ? (`${request?.requestedAmount} NGN`): '-'}</td>
                                            <td className="pt-md-4 pt-3">{request?.requestedAmount ? (`${request?.requestedAmount} NGN`): '-'} </td>
                                            <td className="pt-md-4 pt-3">{request?.requestedAmount ? (`${request?.requestedAmount + request?.interestAmount} NGN`): '-'}  </td>
                                            <td className="pt-md-4 pt-3">{moment(request?.createdAt).format('DD/MM/YYYY')}</td>
                                            <td className="pt-md-4 pt-3">{request?.interestAmount ? (`${request?.interestAmount} NGN`): '-'} </td>
                                            <td className="pt-md-4 pt-3">{getDateFromNo(request?.salaryDueDate)}</td>
                                            <td className="pt-md-4 pt-3 textCapitalize" >{request?.status?request?.status:'-'}</td>
                                            {/* <td className="pt-md-4 pt-3"><NavLink to={`${RouterConstant.transaction}/${request?._id}`} className="btn-outline-incash">View</NavLink></td> */}
                                            {/* <td className="pt-md-4 pt-3"><span onClick={() => setViewRequest(request)} className="btn-outline-incash link-primary">View</span></td> */}
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                        <div className="my-5 d-flex justify-content-center">
                            <ReactPaginate
                                ref={pagination}
                                pageCount={apiData?.total / limit}
                                pageRangeDisplayed={limit}
                                marginPagesDisplayed={1}
                                onPageChange={setPage}
                                containerClassName="pagination"
                                activeClassName="active"
                                pageLinkClassName="page-link"
                                breakLinkClassName="page-link"
                                nextLinkClassName="page-link"
                                previousLinkClassName="page-link"
                                pageClassName="page-item"
                                breakClassName="page-item"
                                nextClassName="page-item"
                                previousClassName="page-item"
                                previousLabel={<>&laquo;</>}
                                nextLabel={<>&raquo;</>}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {
                loader ?
                    <LoaderWrapper /> : ''
            }
        </>
    )
}

export default Transaction
