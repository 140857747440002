import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { RouterConstant } from '../constants/RouteConstant';
import { useForm } from 'react-hook-form';
import { ReactComponent as Logo } from '../assets/images/logo.svg';
import { ReactComponent as NumberIcon } from '../assets/images/icons/Nigeria_no_icon.svg'
import { ReactComponent as DropdownIcon } from '../assets/images/icons/dropdown_icon.svg'
import { signUpApiFun } from '../API/Services/authService';

import './auth.scss';
import { useAlert } from 'react-alert';
import LoaderWrapper from '../Components/LoaderWrapper/LoaderWrapper';
import { checkToken } from '../helper/helper';
import authBg from '../assets/images/background/auth-bg.png';

const Signup = () => {
    let navigate = useNavigate()
    const alert = useAlert();
    const [loader, setLoader] = useState(false);

    const [errorNumber, setErrorNumber] = useState(false)
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    // const checkToken = () => {
    //     let isAuthenticated = localStorage.getItem('token')
    //     console.log('token', isAuthenticated)
    //     navigate(RouterConstant.Home)
    // }

    // useEffect(()=>{
    //     if(checkToken()){
    //         navigate('/')
    //     } 
    // },[])

    const handleSignUp = (formData) => {
        console.log(formData)
        setLoader(true)
        let newPhone = formData.phn
        if(newPhone.length > 10){
            newPhone = newPhone.substring(1)
        }
        let data = {
            "mobile": newPhone,
            "firstName": formData.fname,
            "lastName": formData.lname
        }
        signUpApiFun(data)
            .then((response) => {
                // console.log(response)
                setLoader(false)
                if (response?.data?.status === 'OK') {
                    alert.success(response?.data?.message)
                    localStorage.setItem('tempOtp', response?.data?.data?.otp)
                    navigate(RouterConstant.AuthOtp, { state: { mobile: formData.phn, prevLink: "signUp" } })
                }
                else {
                    console.log("Sign UP failed", response)
                }
            })
            .catch(error => {
                if (error?.statusCode === 400)
                    setErrorNumber(true)
                else
                    console.log(error)
                setLoader(false)
                alert.error(error?.message)
            })
    }
    return (
        <>
            <div className="auth-wrapper" style={{backgroundImage: `url(${authBg})`}}>
                <div className="auth-inner-wrapper">
                    <div className="auth-header">
                        <div className="logo-wrapper">
                            <Logo className="logo-icon" />
                        </div>
                        {/* <h2 className="auth-heading text-center">
                            Access your salary anytime, anywhere
                        </h2>
                        <p className="auth-sub-heading-1 text-center">Get Started</p>
                        <p className="auth-sub-heading-2 text-center">Sign Up</p> */}
                    </div>
                    <form className="form-wrapper" onSubmit={handleSubmit(handleSignUp)}>
                        <div className="input-wrapper">
                                <label>First Name</label>
                                <input type="text" className={`input-box form-control ${errors.fname ? "not-valid" : ""}`}
                                    {...register('fname', { required: true, pattern: /^[A-Za-z]+$/i })}
                                    id="fname" placeholder="First Name" />
                            </div>
                        <div className="input-wrapper">
                        <label>Last Name</label>
                                <input type="text" className={`input-box form-control ${errors.lname ? "not-valid" : ""}`}
                                    {...register('lname', { required: true, pattern: /^[A-Za-z]+$/i })}
                                    id="lname" placeholder="Last Name" />
                            </div>
                        <div className="input-wrapper">
                        <label>Phone Number</label>
                            <div className='position-relative'>
                                <div className="phn-details-placeholder">
                                    <NumberIcon />
                                    <p>+234</p>
                                    <DropdownIcon />
                                </div>
                                <input type="number" className={`form-control phn-field input-box ${errors.phn ? "not-valid" : ""}`}
                                    {...register('phn', { required: true, minLength: 10, maxLength: 11 })}
                                    id="number" placeholder="Phone Number"
                                />
                            </div>

                            {/* error section */}
                            {errors.phn && (errors.phn.type === "minLength" || errors.phn.type === "maxLength") && <small className="error-msg">Please enter a correct phone number</small>}
                            {((errors.fname && errors.fname.type === "required") || (errors.lname && errors.lname.type === "required") || (errors.phn && errors.phn.type === "required"))
                                && <small className="error-msg">Please enter all the details inorder to proceed</small>}
                            {errorNumber && <small className="error-msg">This number is already registered with us</small>}

                        </div>
                        <div className="text-center submit w-100">
                            <button type="submit" className="btn primary-btn w-100">Continue</button>
                        </div>
                        <p className="text-md-center auth-navigation">
                            Already have an account <Link to={RouterConstant.Login} className="heighlight">Sign In</Link>
                        </p>
                    </form>
                </div>
            </div>
            {
                loader ?
                    <LoaderWrapper /> :
                    ''
            }
        </>
    )
}

export default Signup
