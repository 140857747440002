import { CheckedIcon, InfoIcon, RejectIcon } from "../../svg";

const FieldBadge = ({ status }) => {
    switch (status) {
        case 1:
            return <span><InfoIcon color="#FFCC00" /></span>;
            break
        
            case 3:
                return <span className="checked_icon"><RejectIcon /></span>;
            break
        
            default:
                return <span className="checked_icon"><CheckedIcon /></span>;
        }
    }


export default FieldBadge;