import moment from 'moment'
import { Input } from 'reactstrap';
import { Controller, useForm } from 'react-hook-form'
import React, { useEffect, useState } from 'react';
import { companyList } from '../../API/Services/authService';
import { useAlert } from 'react-alert';
import LoaderWrapper from '../../Components/LoaderWrapper/LoaderWrapper';
import FieldBadge from '../../Components/Common/badge/FieldBadge';
import { fieldBadgeClassName } from '../../helper/helper';
const BasicDetails = ({ user }) => {
    const [companyName, setCompanyName] = useState([]);
    const alert = useAlert();
    const [loader, setLoader] = useState(false);

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
        control,
    } = useForm();
    let date = moment(user?.dob).format('DD/MM/YYYY');

    useEffect(() => {
        setLoader(true)
        companyList()
            .then(response => {
                setLoader(false)
                if (response?.data?.status === 'OK') {
                    setCompanyName(response?.data?.data)
                }
                else {
                    console.log(response)
                }
            })
            .catch(error => {
                console.log(error)
                setLoader(false)
                alert.error(error?.message)
            })
    }, [])
    return (
        <>
            <div className="fill-details-wrapper"> 
               <div className='row'>
                <div className='col-xl-8 col-lg-10'>
                    <form className="form-wrapper">
                            
                        <div className="input-wrapper">
                            <label className="input-label-profile" htmlFor="fname">First Name: <FieldBadge status={user?.fieldStatus?.firstName} /> </label>
                            <input
                                type="text"
                                className={`input-box-profile form-control ${fieldBadgeClassName(user?.fieldStatus?.firstName)}`}
                                value={user?.firstName}
                                id="fname"
                                placeholder=""
                                disabled={user?.basicDetailsStatus === -1 ? false : true} />
                        </div>
                            

                        <div className="input-wrapper">
                            <label className="input-label-profile" htmlFor="lname">Last Name: <FieldBadge status={user?.fieldStatus?.lastName} /></label>
                            <input
                                type="text"
                                className={`input-box-profile form-control ${fieldBadgeClassName(user?.fieldStatus?.lastName)}`}
                                value={user?.lastName}
                                id="lname"
                                placeholder=""
                                disabled={user?.basicDetailsStatus === -1 ? false : true} />
                        </div>
                            

                        <div className="input-wrapper">
                            <label className="input-label-profile" htmlFor="lname">Gender: <FieldBadge status={user?.fieldStatus?.gender} /></label>
                            <input
                                type="text"
                                className={`input-box-profile form-control ${fieldBadgeClassName(user?.fieldStatus?.gender)}`}
                                value={user?.gender}
                                id="lname"
                                placeholder=""
                                disabled={user?.basicDetailsStatus === -1 ? false : true} />
                        </div>


                        <div className="input-wrapper">
                            <label className="input-label-profile" htmlFor="phn">Phone Number: <FieldBadge status={user?.fieldStatus?.mobile} /></label>
                            <input
                                type="number"
                                className={`input-box-profile form-control ${fieldBadgeClassName(user?.fieldStatus?.mobile)}`}
                                value={user?.mobile}
                                id="phn"
                                placeholder=""
                                disabled={user?.basicDetailsStatus === -1 ? false : true} />
                        </div>
                            

                        <div className="input-wrapper">
                            <label className="input-label-profile" htmlFor="dob">Date of Birth: <FieldBadge status={user?.fieldStatus?.dob} /></label>
                            <input
                                type="text"
                                className={`input-box-profile form-control ${fieldBadgeClassName(user?.fieldStatus?.dob)}`}
                                value={date}
                                id="dob"
                                placeholder=""
                                disabled={user?.basicDetailsStatus === -1 ? false : true} />
                        </div>

                            
                        <div className="input-wrapper">
                            <label className="input-label-profile" htmlFor="company-name">Company Name: <FieldBadge status={user?.fieldStatus?.companyName} /></label>

                            {
                                user?.basicDetailsStatus === -1 ? <Controller
                                control={control}
                                name="companyName"
                                render={({ field: { onChange, value } }) => (
                                        <Input
                                            disabled={false}
                                            type="select"
                                            className={`form-control input-box-profile ${errors.companyName ? "not-valid" : ""} ${fieldBadgeClassName(user?.fieldStatus?.companyName)}`}
                                            value={user?.companyName ? user?.companyName : user?.workDetails?.companyName}
                                            id="company-name"
                                            onChange={onChange}>
                                            
                                            <option value="" selected disabled>Company Name</option>
                                            {companyName?.map((company, key) => <option value={company.companyName} key={key}>{company.companyName}</option>)}
                                    </Input>
                                )}/> : <input
                                        type="text"
                                        className={`input-box-profile form-control`}
                                        value={user?.companyName ? user?.companyName : user?.workDetails?.companyName}
                                        disabled={true} />
                            }
                            
                        </div>
                    </form>
                </div>
               </div>
            </div>
            {loader ? <LoaderWrapper /> : ''}
        </>
    )
}

export default BasicDetails
