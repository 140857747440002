import ApiInstance from "../../config/intercepter"
import Api from "./../../config/api";
import { setHeaderType } from "../../helper/helper";

export function processRequestAmount(payload){
    // setHeaderType("application/json")
    return ApiInstance.get(`${Api.requestAmount}?requestedAmount=${payload}`)
  }
  export function submitRequest(payload){
    setHeaderType("application/json")
    return ApiInstance.post(`${Api.submitRequest}`, payload)
  }