import { useEffect, useState } from "react"
import FieldBadge from "./badge/FieldBadge";

const BankDetailsBadge = ({ user }) => {
    const [status, setStatus] = useState(0);

    useEffect(() => {
        if (user.fieldStatus) {
            let { accountNo, bankName, accountHolderName } = user.fieldStatus;
            if (accountNo == 3 || bankName == 3 || accountHolderName == 3) {
                setStatus(3)
            } else if (accountNo == 2 && bankName == 2 && accountHolderName == 2) {
                setStatus(2)
            } else {
                setStatus(1)
            }

        }
    }, [user.fieldStatus])

    return (
        <span>
            <FieldBadge status={status} />
            <span className={`badge ms-3 badge-msg ${status == 3 ? "bg-danger" : status == 1 ? 'bg-secondary' : status == 2 ? "bg-success" : ''}`}>
                {`${status == 3 ? "Rejected" : status == 1 ? ' Pending verification' : status == 2 ? "Accepted" : ''}`}
            </span>
        </span>  
    )
}

export default BankDetailsBadge;