import React from 'react'
import './loader.scss';

function LoaderWrapper() {
    return (
        <div className="loader-wrapper">
            <div className="spinner-border m-5 text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    )
}

export default LoaderWrapper
