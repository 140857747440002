import React, { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { ReactComponent as DateIcon } from '../assets/images/icons/date_icon_one.svg'
import { ReactComponent as Logo } from '../assets/images/logo.svg';
import './auth.scss';
import ReactDatePicker from 'react-datepicker';
import { Input } from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import { getUserId, setToken, setUserId } from '../helper/helper';
import { addUserDetailsApiFun, companyList } from '../API/Services/authService';
import { RouterConstant } from '../constants/RouteConstant';
import LoaderWrapper from '../Components/LoaderWrapper/LoaderWrapper';
import { useAlert } from 'react-alert';
import authBg from '../assets/images/background/auth-bg.png';


const AddUserDetails = () => {
    let navigate = useNavigate()
    const alert = useAlert();
    const [loader, setLoader] = useState(false);
    // const [birthDate, setBirthDate] = useState();
    const [companyName, setCompanyName] = useState([]);
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
    } = useForm();

    useEffect(()=>{
        setLoader(true)
        companyList()
            .then(response => {
                setLoader(false)
                if (response?.data?.status === 'OK') {
                    setCompanyName(response?.data?.data)
                }
                else {
                    console.log(response)
                }
            })
            .catch(error => {
                console.log(error)
                setLoader(false)
                alert.error(error?.message)
            })
    },[])


    const handleAddUserDetails = (formData) => {
        setLoader(true)
        let userId = getUserId()
        let date = moment(formData.dob).format('DD/MM/YYYY')
        let data = {
            "userId": userId,
            "dob": date,
            "gender": formData.gender.toLowerCase(),
            "isAgreeWithTNC": formData.checkTerms,
            "companyName": formData.companyName
        }
        // console.log(data)
        addUserDetailsApiFun(data)
            .then(response => {
                setLoader(false)
                if (response?.data?.status === 'OK') {
                    console.log("Data submitted")
                    alert.success(response?.data?.message)
                    setToken(response?.data?.data?.token)
                    setUserId(response?.data?.data?.userId)
                    navigate(RouterConstant.Layout)
                }
                else {
                    console.log(response)
                }
            })
            .catch(error => {
                console.log(error)
                setLoader(false)
                alert.error(error?.message)
            })
    }

    return (
        <>
            <div className="auth-wrapper" style={{backgroundImage: `url(${authBg})`}}>
                <div className="auth-inner-wrapper">
                    <div className="auth-header">
                        <div className="logo-wrapper mb-5 mb-md-0">
                            <Logo className="logo-icon mb-5 mb-md-0" />
                        </div>
                    </div>
                    <form className="form-wrapper" onSubmit={handleSubmit(handleAddUserDetails)}>
                        <div className="input-wrapper">
                            {/* <input type="date" className={`form-control input-box ${errors.date ? "not-valid" : ""}`}
                            {...register('date', { required: true })}
                            id="date" placeholder="Date of Birth"
                        /> */}
                            <Controller
                                render={({ field: { onChange, value } }) => (
                                    <ReactDatePicker
                                        showPopperArrow={false}
                                        className={`form-control input-box ${errors.dob ? "not-valid" : ""}`}
                                        dateFormat="dd/MM/yyyy"
                                        selected={value}
                                        onChange={onChange}
                                        placeholderText="Date of birth"
                                        maxDate={new Date()}
                                        showYearDropdown
                                        dropdownMode="select"
                                        scrollableYearDropdown

                                    // withPortal
                                    />
                                )}
                                control={control}
                                name="dob"
                                rules={{ required: "Please fill your Dob" }}
                            />

                            <DateIcon className="input-icon d-block d-md-none" />
                            {errors.dob && errors.dob.type === "required" && <small className="error-msg">Please fill your Dob</small>}
                        </div>

                        <div className="input-wrapper">
                            <Controller
                                render={({ field: { onChange, value } }) => (
                                    <Input type="select" className={`form-control input-box ${errors.gender ? "not-valid" : ""}`} style={{ color: "#A1A1A1" }}
                                        value={value}
                                        id="gender"
                                        onChange={onChange}
                                    >
                                        <option value="" selected disabled>Select Gender</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Other">Other</option>
                                    </Input>
                                )}
                                control={control}
                                name="gender"
                                rules={{ required: "Please select your gender" }}
                            />
                            {errors.gender && errors.gender.type === "required" && <small className="error-msg">Please select your gender</small>}
                        </div>

                        

                        <div className="input-wrapper">
                        <Controller
                            render={({ field: { onChange, value } }) => (
                                <Input type="select" className={`form-control input-box ${errors.companyName ? "not-valid" : ""}`}
                                    value={value}
                                    id="company-name"
                                    onChange={onChange}
                                >
                                    <option value="" selected disabled>Company Name</option>
                                    {companyName?.map((company, key) => {
                                        return <option value={company.companyName} key={key}>{company.companyName}</option>
                                    })}
                                    {/* <option value="Johns Group">Johns Group</option>
                                    <option value="Yost and Sons">Yost and Sons</option>
                                    <option value="Abernathy Group">Abernathy Group</option> */}
                                </Input>
                            )}
                            control={control}
                            name="companyName"
                            // rules={{ required: "Please select company name" }}
                        />
                        {/* {errors.companyName && errors.companyName.type === "required" && <small className="error-msg">Please select Company name</small>} */}
                    </div>

                        {/* check terms */}
                        <div className="input-wrapper check-terms text-md-center">
                            <input type="checkbox" id="checkTerms"
                                {...register('checkTerms', { required: true })}
                                name="checkTerms" value="true" />
                            <label htmlFor="checkTerms"> I agree to the <span className="heighlight">{`Terms & Conditions`}</span></label>
                            {errors.checkTerms && errors.checkTerms.type === "required" && <small className="error-msg">{"you need to agree to our T&C inorder to proceed"}</small>}
                        </div>
                 

                        <div className="text-center submit w-100">
                            <button type="submit" className="btn primary-btn w-100">Finish</button>
                        </div>
                    </form>
                </div>
            </div>
            {
                loader ?
                    <LoaderWrapper /> :
                    ''
            }
        </>
    )
}

export default AddUserDetails
