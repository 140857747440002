import React, { useRef, useState } from 'react';
import { RouterConstant } from '../constants/RouteConstant';
import { ReactComponent as Logo } from '../assets/images/logo.svg';
import './auth.scss';
import SuccessPanel from '../Components/Common/SuccessPanel';
import { Controller, useForm } from 'react-hook-form';
import { createPwdFun } from '../API/Services/authService';
import { getToken, getUserId } from '../helper/helper';
import LoaderWrapper from '../Components/LoaderWrapper/LoaderWrapper';
import { useAlert } from 'react-alert';
import OtpInput from 'react-otp-input';
import { useNavigate } from 'react-router-dom';
import authBg from '../assets/images/background/auth-bg.png';

const CreatePassword = () => {
    let navigate = useNavigate();
    const alert = useAlert();
    const [loader, setLoader] = useState(false);
    const [resetSuccess, setResetSuccess] = useState(false)
    const {
        register,
        handleSubmit,
        control,
        watch,
        formState: { errors },
    } = useForm()
    const password = useRef({});
    password.current = watch("password", "");

    const handleCreatePassword = (formData) => {
        let userId = getUserId()
        let token = localStorage.getItem('tempToken')
        // setPassword(formData.password)
        // setRePassword(formData.rePassword)

        // if (formData.password === formData.rePassword) {
        setLoader(true)
        createPwdFun({ ...formData, userId: userId, passwordSecret: token })
            .then((response) => {
                setLoader(false)
                if (response?.data?.status === 'OK') {
                    alert.success(response?.data?.message)
                    navigate(RouterConstant.AddUserDetails)
                }
                else {
                    console.log(response)
                }
            })
            .catch(error => {
                console.log(error)
                setLoader(false)
                alert.error(error?.message)
            })
        
    }
    return (
        <>
            <div className="auth-wrapper" style={{backgroundImage: `url(${authBg})`}}>
                {!resetSuccess ?
                    <div className="auth-inner-wrapper">
                        <div className="auth-header">
                            <div className="logo-wrapper">
                                <Logo className="logo-icon" />
                            </div>
                            <h2 className="auth-heading text-center">
                                Reset Pin
                            </h2>
                            <p className="auth-sub-heading-1 text-center"> Please generate your 4 digit pin and confirm it</p>
                        </div>

                        <form className="form-wrapper" onSubmit={handleSubmit(handleCreatePassword)}>
                            <div className="input-wrapper">
                                <label className="input-label d-block text-center fw-bold text-uppercase" htmlFor="bank-name">Enter Pin</label>
                                <Controller
                                    render={({ field: { onChange, value } }) => (
                                        <OtpInput
                                            value={value}
                                            onChange={onChange}
                                            containerStyle="input-otp-wrapper"
                                            inputStyle={`input-otp ${errors.password ? "not-valid" : ""}`}
                                            focusStyle="focus-style"
                                            numInputs="4"
                                            shouldAutoFocus="true"
                                            isInputNum={true}
                                            isInputSecure={true}
                                        />
                                    )}
                                    control={control}
                                    name="password"
                                    rules={{ required: true }}
                                />
                                {errors.password && errors.password.type === "required" && <p className="error-msg text-center">Please Enter Pin</p>}
                                {/* <input type="password" className={`form-control input-box ${errors.password ? "not-valid" : ""}`}
                                    {...register('password', { required: true, minLength: 4, maxLength: 4, pattern: /[^0-9]/})}
                                    id="password" placeholder="Reset Pin, Ex: 1024" /> */}
                            </div>
                            <div className="input-wrapper">
                                <label className="input-label  d-block text-center fw-bold text-uppercase" htmlFor="bank-name">Confirm Pin</label>
                                <Controller
                                    render={({ field: { onChange, value } }) => (
                                        <OtpInput
                                            value={value}
                                            onChange={onChange}
                                            containerStyle="input-otp-wrapper"
                                            inputStyle={`input-otp ${errors.rePassword ? "not-valid" : ""}`}
                                            focusStyle="focus-style"
                                            numInputs="4"
                                            isInputNum={true}
                                            isInputSecure={true}
                                        />
                                    )}
                                    control={control}
                                    name="rePassword"
                                    rules={{ 
                                        required: true,
                                        validate: value =>
                                            value === password.current || "The Pins did not match"
                                    }}
                                />
                                {errors.rePassword && errors.rePassword.type === "required" && <p className="error-msg text-center">Please Confirm Pin</p>}
                                {/* <input type="password" className={`form-control input-box ${errors.rePassword ? "not-valid" : ""}`}
                                    {...register('rePassword', { required: true })}
                                    id="re-password" placeholder="Confirm Pin, Ex: 1024" /> */}
                                {errors.rePassword && <p className="error-msg text-center">{errors.rePassword.message}</p>}
                            </div>
                       

                            <div className="text-center submit w-100">
                                <button type="submit" className="btn primary-btn w-100">Generate Pin</button>
                            </div>
                            
                        </form>
                    </div>
                    : <SuccessPanel
                        msg="Password reset successfully"
                        linkText="Login"
                        link={RouterConstant.Login}
                    />
                }
            </div>
            {
                loader ?
                    <LoaderWrapper /> :
                    ''
            }
        </>
    )
}

export default CreatePassword
