export const development = {
    api: {
        url: 'https://portal.incash.africa:5657/v1/',
        // url: 'https://api.incash.africa/v1/',
    }
    
}


export const staging = {
    api: {
        url: 'https://portal.incash.africa:5657/v1/',
    }
}