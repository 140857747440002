import { useEffect, useState } from "react"
import FieldBadge from "./badge/FieldBadge";

const BasicDetailsBadge = ({ user }) => {
    const [status, setStatus] = useState(0);

    useEffect(() => {
        if (user.fieldStatus) {
            let { firstName, lastName, gender, mobile, dob, companyName } = user.fieldStatus;

            if (firstName == 3 || lastName == 3 || gender == 3 || mobile == 3 || dob == 3 || companyName == 3) {
                setStatus(3)
            } else if (firstName == 2 && lastName == 2 && gender == 2 && mobile == 2 && dob == 2 && companyName == 2) {
                setStatus(2)
            } else {
                setStatus(1)
            }
        }
    }, [user.fieldStatus])

    return (
        <span>
            <FieldBadge status={status} />
            <span className={`badge ms-3 badge-msg ${status == 3 ? "bg-danger" : status == 1 ? 'bg-secondary' : status == 2 ? "bg-success" : ''}`}>
                {`${status == 3 ? "Rejected" : status == 1 ? ' Pending verification' : status == 2 ? "Accepted" : ''}`}
            </span>
        </span>  
    )
}

export default BasicDetailsBadge;