import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { RouterConstant } from '../../constants/RouteConstant'
import LoaderWrapper from '../LoaderWrapper/LoaderWrapper'
import './logout.scss';
const Logout = ({ isOpen, toggleLogout }) => {

    const navigate = useNavigate()
    const [loader, setLoader] = useState(false);

    const handleLogout = () => {
        localStorage.clear();
        setLoader(true)
        setTimeout(() => {
            setLoader(false)
            navigate(RouterConstant.Login)
        }, 500)
    }
    return (
        <>{
            loader ? <LoaderWrapper /> :
                <Modal
                    isOpen={isOpen}
                    toggle={toggleLogout}
                    centered
                    size='md'
                >
                    <ModalBody className="logout-body">
                        <div className='logout-body-inner-wrapper'>
                            Logout of this account?
                        </div>
                        <div className='logout-btn-wrapper'>
                            <Button
                                className="btn primary-btn"
                                onClick={handleLogout}>
                                Logout
                            </Button>
                            <Button
                                className="btn white-btn border-0"
                                onClick={toggleLogout}
                            >
                                Cancel
                            </Button>
                        </div>
                    </ModalBody>
                </Modal>
        }
        </>
    )
}

export default Logout
