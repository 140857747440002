import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import LoaderWrapper from '../Components/LoaderWrapper/LoaderWrapper';
import { changePasswordService } from '../API/Services/authService';
import OtpInput from 'react-otp-input';
import { RouterConstant } from '../constants/RouteConstant';
import { useNavigate } from 'react-router-dom';
import { useAlert } from 'react-alert';
import authBg from '../assets/images/background/auth-bg.png';

const ChangePassword = props => {
    const password = useRef({});
    const alert = useAlert();
    let navigate = useNavigate();
    const [apiData, setApiData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [isValidForm, setIsValidForm] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        control,
        formState: { errors },
    } = useForm();
    password.current = watch("password", "");

    // organization status
    const changePassword = (data) => {
        setLoader(true)
        changePasswordService(data)
            .then(response => {
                if (response?.data?.status === 'OK') {
                    alert.success(response?.data?.message)
                    navigate(RouterConstant.Layout)
                }
                else {
                    console.log(response)
                }
                setLoader(false);
            })
            .catch(err => {
                console.log("error", err);
                alert.error(err?.message)
                setLoader(false);
            })
    }


    return (
        <div className="generic-wrapper">
            {loader ? <div className="loader_lg"><LoaderWrapper /></div> : ''}
            <div className="content-route">
            <h3 className="generic-heading">Change Password</h3>
                <div className="card  px-5 py-4 col-lg-6">
                    <div className={`form-message ${isValidForm ? "text-success" : "text-danger"}`}>{errorMessage}</div>
                    <form onSubmit={handleSubmit(changePassword)}>
                        <div className="form-group mb-4 col-md-5">
                            <div className="input-wrapper">
                            <label className="input-label">Old Password</label>
                            <Controller
                                render={({ field: { onChange, value } }) => (
                                    <OtpInput
                                        value={value}
                                        onChange={onChange}
                                        containerStyle="input-otp-wrapper justify-content-start"
                                        inputStyle={`input-otp ${errors.oldPassword ? "not-valid" : ""}`}
                                        focusStyle="focus-style"
                                        numInputs="4"
                                        isInputNum={true}
                                        isInputSecure={true}
                                        shouldAutoFocus={true}
                                    />
                                )}
                                control={control}
                                name="oldPassword"
                            />
                            {errors.oldPassword && errors.oldPassword.type === "required" && <p className="input-error">Enter old password</p>}
                                    </div>
                        </div>

                        <div className="form-group mb-4 col-md-5">
                            <div className="input-wrapper ">
                            <label className="input-label">New Password</label>
                            <Controller
                                render={({ field: { onChange, value } }) => (
                                    <OtpInput
                                        value={value}
                                        onChange={onChange}
                                        containerStyle="input-otp-wrapper justify-content-start"
                                        inputStyle={`input-otp ${errors.password ? "not-valid" : ""}`}
                                        focusStyle="focus-style"
                                        numInputs="4"
                                        isInputNum={true}
                                        isInputSecure={true}
                                    />
                                )}
                                control={control}
                                name="password"
                            />
                            {errors.password && errors.password.type === "required" && <p className="input-error">Password is required.</p>}
                                    </div>
                        </div>

                        <div className="form-group col-md-5">
                            <div className="input-wrapper">
                            <label className="input-label">Confirm Password</label>
                            <Controller
                                render={({ field: { onChange, value } }) => (
                                    <OtpInput
                                        value={value}
                                        onChange={onChange}
                                        containerStyle="input-otp-wrapper  justify-content-start"
                                        inputStyle={`input-otp ${errors.confirmPassword ? "not-valid" : ""}`}
                                        focusStyle="focus-style"
                                        numInputs="4"
                                        isInputNum={true}
                                        isInputSecure={true}
                                    />
                                    )}
                                    control={control}
                                    name="confirmPassword"
                                    rules={{ 
                                        required: true,
                                        validate: value =>
                                        value === password.current || "The Pins did not match"
                                    }}
                                    />
                            {errors.confirmPassword && errors.confirmPassword.type === "required" && <p className="input-error">Confirm Pin is required.</p>}
                            {errors.confirmPassword && <p className="input-error">{errors.confirmPassword.message}</p>}
                                    </div>
                        </div>
                        {/* <div className="d-flex justify-content-end mt-4">
                            <button type="submit" className="btn primary-btn w-100 min-padding">Change Pin</button>
                        </div> */}

                        <div className="w-100 mt-5">
                            <button type="submit" className="btn primary-btn min-padding">Change Pin</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default ChangePassword;