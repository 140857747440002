import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { Input } from 'reactstrap';
import { addBankDetailsApiFun, getUserDetails } from '../../API/Services/authService';
import LoaderWrapper from '../../Components/LoaderWrapper/LoaderWrapper';
import { useAlert } from "react-alert";
import { banks } from '../../constants/StringsConstant';
import FieldBadge from '../../Components/Common/badge/FieldBadge';
import { fieldBadgeClassName } from '../../helper/helper';


const showSaveBtn = (user) => {
    if (user?.fieldStatus) {
        let { accountNo, bankName, accountHolderName } = user.fieldStatus;
        if (accountNo == 2 && bankName == 2 && accountHolderName == 2) {
            return ''
        } else {
            return <div className="save-btn-wrapper">
                <button type="submit" className='btn primary-btn w-auto px-5'>Save</button>
            </div>
        }
    }
}



function BankDetails({ bankDetails, updateData, user }) {
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        control,
    } = useForm();

    const alert = useAlert();
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        setValue('bankName', bankDetails?.bankName)
        setValue('holderName', bankDetails?.accountHolderName)
        setValue('acc', bankDetails?.accountNo)
    }, [bankDetails])

    const handleBankDetails = (formData) => {
        setLoader(true)
        let data = {
            "bankName": formData.bankName,
            "accountNo": formData.acc,
            "accountHolderName": formData.holderName
        }
        addBankDetailsApiFun(data)
            .then(response => {
                setLoader(false)
                if (response?.data?.status === 'OK') {
                    alert.success(response?.data?.message)
                    updateData('4')
                }
                else {
                    console.log('Error response-->', response)
                }
            })
            .catch(error => {
                console.log(error)
                setLoader(false)
                alert.error(error?.message)
            })
    }
    return (
        <>
            <div className="fill-details-wrapper">
                <div className="fill-details-heading">
                    Add Bank Details
                </div>
                <div className='row'>
                    <div className='col-xl-8 col-lg-10'>
                        <form className="" onSubmit={handleSubmit(handleBankDetails)}>
                            <div className='form-wrapper'>

                                <div className="input-wrapper">
                                    <label className="input-label-profile" htmlFor="acc">Bank Account Number: <FieldBadge status={user?.fieldStatus?.accountNo} /></label>
                                    <input
                                        disabled={user?.fieldStatus?.accountNo == 2 ? true : false}
                                        type="type"
                                        className={`input-box-profile form-control ${errors.acc ? "not-valid" : ""} ${fieldBadgeClassName(user?.fieldStatus?.accountNo)}`}
                                        {...register('acc', { required: true })}
                                        id="acc"
                                        placeholder="Bank Account Number" />
                                    {errors.acc && errors.acc.type === "required" && <small className="error-msg">Please enter account number</small>}
                                </div>


                                <div className="input-wrapper">
                                    <label className="input-label-profile" htmlFor="bank-name">Select Bank: <FieldBadge status={user?.fieldStatus?.bankName} /></label>
                                    <Controller
                                        control={control}
                                        name="bankName"
                                        rules={{ required: "Please select your bank" }}
                                        render={({ field: { onChange, value } }) => (
                                            <Input
                                                disabled={user?.fieldStatus?.bankName == 2 ? true : false}
                                                type="select"
                                                className={`input-box-profile form-control ${errors.bankName ? "not-valid" : ""} ${fieldBadgeClassName(user?.fieldStatus?.bankName)}`}
                                                id="bank-name"
                                                value={value}
                                                onChange={onChange}>
                                                <option value="" selected disabled>Select Bank</option>
                                                {banks?.map((bank, key) => <option value={bank.name} key={key}>{bank.name}</option>)}
                                            </Input>
                                        )}/>
                                    {errors.bankName && errors.bankName.type === "required" && <small className="error-msg">Please select your bank</small>}
                                </div>


                                <div className="input-wrapper">
                                    <label className="input-label-profile" htmlFor="holder-name">Account Holder Name: <FieldBadge status={user?.fieldStatus?.accountHolderName} /></label>
                                    <input
                                        disabled={user?.fieldStatus?.accountHolderName == 2 ? true : false}
                                        type="text"
                                        className={`input-box-profile form-control ${errors.holderName ? "not-valid" : ""}  ${fieldBadgeClassName(user?.fieldStatus?.accountHolderName)}`}
                                        {...register('holderName', { required: true })}
                                        id="holder-name"
                                        placeholder="Account Holder Name" />
                                        {errors.holderName && errors.holderName.type === "required" && <small className="error-msg">Please enter account holder name</small>}
                                </div>

                            </div>
                            {user ? showSaveBtn(user) : ''}
                        </form>
                    </div>
                </div>
            </div>
            {
                loader ?
                    <LoaderWrapper /> :
                    ''
            }
        </>
    )
}

export default BankDetails
