import ApiInstance from "./../../config/intercepter";
import Api from "./../../config/api";
import { setHeaderType } from "../../helper/helper";

// sign up
export function signUpApiFun(payload){
  setHeaderType("application/json")
  return ApiInstance.post(`${Api.signUp}`, payload)
}

// otp
export function otpApiFun(payload){
  setHeaderType("application/json")
  return ApiInstance.post(`${Api.otp}`, payload)
}

// resend otp
export function resendOtpFun(payload){
  setHeaderType("application/json")
  return ApiInstance.post(`${Api.resendOtp}`, payload)
}

// create password
export function createPwdFun(payload){
  setHeaderType("application/json")
  return ApiInstance.post(`${Api.createPwd}`, payload)
}
// login
export function loginApiFun(payload) {
  setHeaderType("application/json")
  return ApiInstance.post(`${Api.login}`, payload);
}

// forgot password
export function forgotPasswordService(payload) {
  setHeaderType("application/json")
  return ApiInstance.post(`${Api.forgotPassword}`, payload);
}

// reset Password
export function resetPasswordApiFun(payload) {
  setHeaderType("application/json")
  return ApiInstance.post(`${Api.resetPassword}`, payload);
}

// add user details
export function addUserDetailsApiFun(payload) {
  setHeaderType("application/json")
  return ApiInstance.post(`${Api.addUserDetails}`, payload) 
}

// add bank details
export function addBankDetailsApiFun(payload) {
  setHeaderType("application/json")
  return ApiInstance.post(`${Api.addBankDetails}`, payload,
  )
}

// add kyc details
export function addKycDetailsApiFun(payload) {
  setHeaderType("application/json")
  return ApiInstance.post(`${Api.addKycDetails}`, payload)
}

// upload files for KYC
export function uploadNinSlip(payload) {
  setHeaderType("multipart/form-data")
  return ApiInstance.post(`${Api.uploadNinSlip}`, payload)
}

export function uploadValidId(payload) {
  setHeaderType("multipart/form-data")
  return ApiInstance.post(`${Api.uploadValidId}`, payload)
}
export function uploadUtility(payload) {
  setHeaderType("multipart/form-data")
  return ApiInstance.post(`${Api.uploadUtility}`, payload)
}

// add work details
export function addWorkDetailsApiFun(payload) {
  setHeaderType("multipart/form-data")
  return ApiInstance.post(`${Api.addWorkDetails}`, payload)
}

//get user details
export function getUserDetails(payload) {
  setHeaderType("application/json")
  return ApiInstance.get(`${Api.getUserDetails}`)
}

//check profile status
export function getProfileStatus() {
  return ApiInstance.get(`${Api.checkProfileStatus}`)
}
// export function logoutApiFun(payload) {
//   return ApiInstance.post(`${Api.logout}`, payload);
// }

//home dashboard api
export function gethomeDashboardInfo(){
  return ApiInstance.get(`${Api.homeDashboard}`)
}

// withdraw recent
export function getWithdrawRecent(){
  return ApiInstance.get(`${Api.withdrawRecent}`)
}

// change Pawwsord
export function changePasswordService(payload){
  setHeaderType("application/json")
  return ApiInstance.post(`${Api.changePassword}`,payload)
}

export function companyList(){
  setHeaderType("application/json")
  return ApiInstance.get(`${Api.companyList}`)
}


export function getCompanyContractorSite(data) {
  return ApiInstance.get(`${Api.getCompanyContractorSite}?companyName=${data}`);
}


export function cancelRequestService(payload) {
  setHeaderType("application/json");
  return ApiInstance.post(`${Api.cancelRequest}`, payload);
}

export function addReferYourEmployer(payload) {
  setHeaderType("application/json")
  return ApiInstance.post(`${Api.referEmployer}`, payload) 
}

