import React from 'react'
import {Link} from 'react-router-dom'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { RouterConstant } from '../../constants/RouteConstant'
import './error.scss'
const ErrorModal = ({ isOpen, toggleError, message, isProfileComplete }) => {
    return (
        <>
            <Modal
                isOpen={isOpen}
                toggle={toggleError}
            >
                <ModalHeader toggle={toggleError} className='error-header'>
                    Error
                </ModalHeader>
                <ModalBody className="error-body ">
                    <span className='text-danger'>{message}</span>
                    {
                        isProfileComplete == 'show' &&
                        <div className='text-center mt-4'>
                            <Link to={RouterConstant.FillProfile} className='btn primary-btn'>Complete Your Profile</Link>
                        </div>
                    }
                </ModalBody>
            </Modal>
        </>
    )
}

export default ErrorModal
