export const RouterConstant = {
    Layout: '/', 
    Home: '', 
    Login: "/login",
    Signup: "/signup",
    ForgotPassword: "/forgot-password",
    ResetPassword: "/reset-password",
    AboutUs: "/about-us",
    CreatePwd: "/create-pwd",
    AddUserDetails: "/add-user-details",
    FillProfile: "/complete-profile",
    AuthOtp: "/enter-otp",
    Request:"request",
    changePassword: "/change-password",
    transaction: "/transaction",
    referYourEmployee: '/refer-your-employer',
    referEmployerThanku: '/thank-you'
}