import React from 'react'
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import AuthOtp from "./Auth/AuthOtp";
import AddUserDetails from "./Auth/AddUserDetails";
import CreatePassword from "./Auth/CreatePassword";
import ForgotPassword from "./Auth/ForgotPassword";
import Login from "./Auth/Login";
import ResetPassword from "./Auth/ResetPassword";
import Signup from "./Auth/Signup";
import { RouterConstant } from "./constants/RouteConstant";
import Layout from "./Layout";
import HomePage from "./Pages/HomePage/HomePage";
import FillProfile from "./Pages/Profile";
import RequestMoney from "./Pages/Request";
import { positions, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import { checkToken } from "./helper/helper";
import ChangePassword from './Auth/ChangePassword';
import Transaction from './Pages/Transactions/Transaction';
import ReferYourEmployer from './Pages/ReferYourEmployer';
import ReferEmployerThankYou from './Pages/ReferYourEmployer/refer-employer-thank';
const options = {
  timeout: 3000,
  position: positions.BOTTOM_CENTER
};

// document.body.style.zoom = 0.786

function RequireAuth({ children, redirectTo }) {
  let isAuthenticated = checkToken();
  return isAuthenticated ? children : <Navigate to={redirectTo} />;
}

function PublicAuth({ children, redirectTo }) {
  let isAuthenticated = !checkToken();
  //   if(isAuthenticated){
  //     return children
  //   }else{
  //     let navigate = useNavigate()
  //     navigate(redirectTo)
  //   }
  return isAuthenticated ? children : <Navigate to={redirectTo} />;
}



function App() {
  return (
    <Provider template={AlertTemplate} {...options}>
      <BrowserRouter>
        <Routes>
          <Route exact path={RouterConstant.Login} element={
            <PublicAuth redirectTo={'/'}>
              <Login />
            </PublicAuth>
          } />
          <Route exact path={RouterConstant.Signup} element={
            <PublicAuth redirectTo={'/'}>
              <Signup />
            </PublicAuth>
          } />
          <Route exact path={RouterConstant.ForgotPassword} element={
            <PublicAuth redirectTo={'/'}>
              <ForgotPassword />
            </PublicAuth>
          } />
          <Route exact path={RouterConstant.ResetPassword} element={
            <PublicAuth redirectTo={'/'}>
              <ResetPassword />
            </PublicAuth>
          } />
          <Route exact path={RouterConstant.CreatePwd} element={
            <PublicAuth redirectTo={'/'}>
              <CreatePassword />
            </PublicAuth>
          } />
          <Route exact path={RouterConstant.AddUserDetails} element={
            <PublicAuth redirectTo={'/'}>
              <AddUserDetails />
            </PublicAuth>
          } />
          <Route exact path={RouterConstant.AuthOtp} element={
            <PublicAuth redirectTo={'/'}>
              <AuthOtp />
            </PublicAuth>
          } />
          <Route exact path={RouterConstant.referYourEmployee} element={
            <ReferYourEmployer />
          } />
          <Route exact path={RouterConstant.referEmployerThanku} element={
            <ReferEmployerThankYou />
          } />


          <Route path={RouterConstant.Layout} element={
            <RequireAuth redirectTo={RouterConstant.Login}>
              <Layout />
            </RequireAuth>
          }>
            <Route path={RouterConstant.Home} element={<HomePage />} />
            <Route path={RouterConstant.changePassword} element={<ChangePassword />} />
            <Route path={RouterConstant.transaction} element={<Transaction />} />
            <Route path={`${RouterConstant.transaction}/:id`} element={<Transaction />} />
            <Route path={RouterConstant.FillProfile} element={<FillProfile />} />
            <Route path={RouterConstant.Request} element={<RequestMoney />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
