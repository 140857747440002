import React,{ useEffect, useState} from 'react'
import { NavLink } from 'react-router-dom'
import { ReactComponent as Logo } from '../assets/images/logo-lg.svg'
import { ReactComponent as HomeIcon } from '../assets/images/icons/home_icon.svg';
import { ReactComponent as RequestIcon } from '../assets/images/icons/request_icon.svg';
import { ReactComponent as PaymentIcon } from '../assets/images/icons/payment_icon.svg';
import { ReactComponent as ProfileIcon } from '../assets/images/icons/user.svg';
import { ReactComponent as LogoutIcon } from '../assets/images/icons/logout_icon.svg';
import { ReactComponent as ChatIcon } from '../assets/images/icons/chat.svg';
import './sidebar.scss'
import Logout from '../Components/LogoutModal/Logout';
import { RouterConstant } from '../constants/RouteConstant';
const Sidebar = ({ toggle, background, toggleSidebar }) => {
    const [sidebarToggleState, setSidebarToggleState] = useState()
    const [isOpen, setIsOpen] = useState(false)
    const handleChildClick = (e) => {
        e.stopPropagation();
    }
    const handleToggleLogout = () =>{
        setIsOpen(!isOpen)
        if(!isOpen){
            toggleSidebar()
        }
    }
    useEffect(() => {
        if (window.innerWidth < 360)
            setSidebarToggleState(false)
        else
            setSidebarToggleState(true)
    }, [window.innerWidth])
    return (
        <>
        <aside className={`sidebar-outer-wrapper ${toggle ? "active" : ""} ${background ? "background-active" : ("")}`} onClick={sidebarToggleState ? toggleSidebar : () => { }}>
            <div className={`sidebar-inner-wrapper`} onClick={handleChildClick}>
                <div className="logo-wrapper">
                    <Logo className="logo" />
                    <i className="fa fa-arrow-left" onClick={!sidebarToggleState ? toggleSidebar : ()=>{}}></i>
                </div>
                <ul className="menu-wrapper">
                    <li className="menu-item-wrapper">
                        <NavLink to={RouterConstant.Home} className="menu-item" onClick={sidebarToggleState ? toggleSidebar : () => { }}>
                            <div className="menu-icon-wrapper"><HomeIcon /></div>
                            <p className="menu-item-text">Home</p>
                        </NavLink>
                    </li>
                    <li className="menu-item-wrapper">
                        <NavLink to={RouterConstant.Request} className="menu-item" onClick={sidebarToggleState ? toggleSidebar : () => { }}>
                            <div className="menu-icon-wrapper"><RequestIcon /></div>
                            <p className="menu-item-text">Request</p></NavLink>
                    </li>
                    <li className="menu-item-wrapper">
                        <NavLink to={RouterConstant.changePassword} className="menu-item" onClick={sidebarToggleState ? toggleSidebar : () => { }}>
                            <div className="menu-icon-wrapper"><PaymentIcon /></div>
                            <p className="menu-item-text">Change Password</p>
                        </NavLink>
                    </li>
                    
                    <li className="menu-item-wrapper">
                        <NavLink to={RouterConstant.transaction} className="menu-item" onClick={sidebarToggleState ? toggleSidebar : () => { }}>
                        <div className="menu-icon-wrapper"><PaymentIcon /></div>
                            <p className="menu-item-text">Transaction</p>
                        </NavLink>
                    </li>
                    <li className="menu-item-wrapper">
                        <NavLink to={RouterConstant.FillProfile} className="menu-item" onClick={sidebarToggleState ? toggleSidebar : () => { }}>
                        <div className="menu-icon-wrapper"><ProfileIcon /></div>
                            <p className="menu-item-text">Profile</p>
                        </NavLink>
                    </li>
                    <li className="menu-item-wrapper log-out-item">
                        <a target="_blank" href="https://api.whatsapp.com/send?phone=2348122109900" className="menu-item">
                            <div className="menu-icon-wrapper"><ChatIcon /></div>
                            <p className="menu-item-text">Chat with us</p>
                            <div class="elfsight-app-8d4775f0-a037-4369-a29c-c37c0b6036b9"></div>
                        </a>
                    </li> 
                    <li className="menu-item-wrapper ">
                        <div onClick ={handleToggleLogout} className="menu-item cursor-pointer">
                            <div className="menu-icon-wrapper"><LogoutIcon /></div>
                            <p className="menu-item-text">Log out</p>
                        </div>
                    </li> 
                </ul>
            </div>
        </aside>
        <Logout 
            isOpen = {isOpen}
            toggleLogout = {handleToggleLogout}
        />
        </>
    )
}

export default Sidebar
