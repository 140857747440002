import { Navigate } from "react-router-dom"

// remove item
export function clearItem(item) {
    return localStorage.removeItem(item)
}
// get token
export function getToken() {
    return localStorage.getItem('token')
}
//  set token
export function setToken(token) {
    return localStorage.setItem('token', token)
}
// check token
export function checkToken() {
    if (localStorage.getItem('token'))
        return true
    else
        return false
}
//  get user ID
export function getUserId() {
    return localStorage.getItem('userId')
}

//  set user ID
export function setUserId(userId) {
    return localStorage.setItem('userId', userId)
}

// set header content type
export function getHeaderType() {
    return localStorage.getItem("contentType")
}
export function setHeaderType(contentType) {
    return localStorage.setItem("contentType", contentType)
}

export const fieldBadgeClassName = status => {
    switch (status) {
        case 1:
            return '';
            break
        
            case 3:
                return 'is-invalid';
            break
        
            default:
                return '';
        }
}

