import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { RouterConstant } from '../constants/RouteConstant';
import { ReactComponent as Logo } from '../assets/images/logo.svg';
import { useForm } from 'react-hook-form';
import './auth.scss';
import { resendOtpFun } from '../API/Services/authService';
import LoaderWrapper from '../Components/LoaderWrapper/LoaderWrapper';
import { useAlert } from 'react-alert';
import authBg from '../assets/images/background/auth-bg.png';

const ForgotPassword = () => {
    let navigate = useNavigate();
    const alert = useAlert();
    const [loader, setLoader] = useState(false);
    const [errorNumber, setErrorNumber] = useState(false)

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const handleForgotPassword = (formData) => {
        setLoader(true)
        let newPhone = formData.phn
        if(newPhone.length > 10){
            newPhone = newPhone.substring(1)
        }
        
        let data = {
            "mobile": newPhone
        }
        resendOtpFun(data)
            .then((response) => {
                setLoader(false)
                if (response?.data?.status === 'OK') {
                    alert.success(response?.data?.message)
                    localStorage.setItem('tempOtp', response?.data?.data?.otp)
                    navigate(RouterConstant.AuthOtp, { state: { mobile: formData.phn, prevLink: "forgotPassword" } })
                }
                else {
                    console.log('Error response-->', response)
                }
            })
            .catch(error => {
                console.log(error)
                setLoader(false)
                alert.error(error?.message)
                if (error?.statusCode === 400)
                    setErrorNumber(true)
            })
    }
    return (
        <>
            <div className="auth-wrapper">
                <div className="auth-inner-wrapper">
                    <div className="auth-header">
                        <div className="logo-wrapper">
                            <Logo className="logo-icon" />
                        </div>
                        <h2 className="auth-heading text-center">
                            Forgot password?
                        </h2>
                        <p className="auth-sub-heading-1 text-center">Please enter the phone number you would like your reset link to be sent to</p>
                    </div>

                    <form className="form-wrapper" onSubmit={handleSubmit(handleForgotPassword)}>
                        <div className="input-wrapper">
                            <input type="number" className={`form-control input-box ${errors.phn ? "not-valid" : ""}`}
                                {...register('phn', { required: true, minLength: 10, maxLength: 11 })}
                                id="number" placeholder="Phone Number" />
                            {errors.phn && (errors.phn.type === "required") && <small className="error-msg">Please enter a number</small>}
                            {errors.phn && (errors.phn.type === "minLength" || errors.phn.type === "maxLength") && <small className="error-msg">Please enter a correct phone number</small>}
                            {errorNumber && <small className="error-msg">Please enter a registered number</small>}
                        </div>
                      

                        <div className="text-center submit w-100">
                            <button type="submit" className="btn primary-btn w-100" style={{ marginTop: '-10px' }}>Request reset link</button>
                        </div>
                        <p className="text-center auth-navigation">
                            <Link to={RouterConstant.Login} className="heighlight">Back to Login</Link>
                        </p>
                    </form>
                </div>
            </div>
            {
                loader ?
                    <LoaderWrapper /> :
                    ''
            }
        </>
    )
}

export default ForgotPassword
