import React, { useState } from 'react'
// import { useNavigate } from 'react-router'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { RouterConstant } from '../../constants/RouteConstant'
import LoaderWrapper from '../LoaderWrapper/LoaderWrapper'
import { ReactComponent as SuccessIcon } from '../../assets/images/icons/success_icon_two.svg'
import { ReactComponent as RighArrowIcon } from '../../assets/images/icons/right_icon_pop.svg'
import './profilestatus.scss'
import { Link } from 'react-router-dom'
const ProfileStatus = ({ isOpen, toggle, data }) => {

    // const navigate = useNavigate()
    const [loader, setLoader] = useState(false);
    console.log("data ==>", data)
    // const handleLogout = () => {
    //     localStorage.clear();
    //     setLoader(true)
    //     setTimeout(()=>{
    //         setLoader(false)
    //         navigate(RouterConstant.Login)
    //     }, 1000)
    // }
    return (
        <>{
            loader ? <LoaderWrapper /> :
                <Modal
                    isOpen={isOpen}
                    toggle={toggle}
                    className="profile-status-wrapper"
                    centered
                >
                    {/* <ModalHeader toggle={toggleProfileStatus}>
                        Profile Status
                    </ModalHeader> */}
                    <ModalBody >
                        {/* <div className="profile-status-wrapper"> */}
                        <div className="profile-status">
                            <div className="heading-wrapper">
                                <h4 className="heading">Please complete the following</h4>
                                <p className="sub-heading">It will only take a few minutes</p>
                            </div>
                            <div className="body-content-wrapper">
                                <div className="body-item">
                                    <div className="details">
                                        Basic Details
                                    </div>
                                    <Link to={RouterConstant.FillProfile} className="status-wrapper">
                                        <p className={`status ${data?.basicDetailsStatus === -1 ? 'text-danger' :
                                            data?.basicDetailsStatus === 0 ? 'text-secondary' :
                                                data?.basicDetailsStatus === 1 ? '' :
                                                    data?.basicDetailsStatus === 2 ? 'verified' : ''}`}>
                                            {data?.basicDetailsStatus === 0 ? 'Not uploaded' :
                                                data?.basicDetailsStatus === 1 ? 'Pending' :
                                                    data?.basicDetailsStatus === 2 ? 'Verified' :
                                                        data?.basicDetailsStatus === -1 ? 'Rejected' : ''
                                            }
                                        </p>
                                        {data?.status === 2 ? <SuccessIcon /> : <RighArrowIcon />}
                                    </Link>
                                </div>
                                <div className="body-item">
                                    <div className="details">
                                        Work Details
                                    </div>
                                    <Link to={RouterConstant.FillProfile} className="status-wrapper">
                                        <p className={`status ${data?.workDetails?.status === -1 ? 'text-danger' :
                                            data?.workDetails?.status === 0 ? 'text-secondary' :
                                                data?.workDetails?.status === 1 ? '' :
                                                    data?.workDetails?.status === 2 ? 'verified' : ''}`}>

                                            {data?.workDetails?.status === 0 ? 'Not uploaded' :
                                                data?.workDetails?.status === 1 ? 'Pending' :
                                                    data?.workDetails?.status === 2 ? 'Verified' :
                                                        data?.workDetails?.status === -1 ? 'Rejected' : ''
                                            }
                                        </p>
                                        {data?.workDetails?.status === 2 ? <SuccessIcon /> : <RighArrowIcon />}
                                    </Link>
                                </div>
                                <div className="body-item">
                                    <div className="details">
                                        KYC Details
                                    </div>
                                    <Link to={RouterConstant.FillProfile} className="status-wrapper">
                                        <p className={`status ${data?.kycDetails?.status === -1 ? 'text-danger' :
                                            data?.kycDetails?.status === 0 ? 'text-secondary' :
                                                data?.kycDetails?.status === 1 ? '' :
                                                    data?.kycDetails?.status === 2 ? 'verified' : ''}`}>

                                            {data?.kycDetails?.status === 0 ? 'Not uploaded' :
                                                data?.kycDetails?.status === 1 ? 'Pending' :
                                                    data?.kycDetails?.status === 2 ? 'Verified' :
                                                        data?.kycDetails?.status === -1 ? 'Rejected' : ''
                                            }
                                        </p>
                                        {data?.kycDetails?.status === 2 ? <SuccessIcon /> : <RighArrowIcon />}
                                    </Link>
                                </div>
                                <div className="body-item">
                                    <div className="details">
                                        Bank Details
                                    </div>
                                    <Link to={RouterConstant.FillProfile} className="status-wrapper">
                                        <p className={`status ${data?.bankDetails?.status === -1 ? 'text-danger' :
                                            data?.bankDetails?.status === 0 ? 'text-secondary' :
                                                data?.bankDetails?.status === 1 ? '' :
                                                    data?.bankDetails?.status === 2 ? 'verified' : ''}`}>

                                            {data?.bankDetails?.status === 0 ? 'Not uploaded' :
                                                data?.bankDetails?.status === 1 ? 'Pending' :
                                                    data?.bankDetails?.status === 2 ? 'Verified' :
                                                        data?.bankDetails?.status === -1 ? 'Rejected' : ''
                                            }
                                        </p>
                                        {data?.bankDetails?.status === 2 ? <SuccessIcon /> : <RighArrowIcon />}
                                    </Link>
                                </div>
                            </div>
                        </div>
                        {/* </div> */}
                    </ModalBody>
                </Modal>
        }
        </>
    )
}

export default ProfileStatus
