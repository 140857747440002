import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { ReactComponent as LogoIcon } from '../../assets/images/logo_light.svg'
import { ReactComponent as SuccessCardIcon } from '../../assets/images/card/success_card.svg'
import { ReactComponent as BannerImage } from '../../assets/images/banner/banner-1.svg'
import './homepage.scss'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import CompleteProfile from '../../Components/Common/CompleteProfile'
import { gethomeDashboardInfo, getProfileStatus, getUserDetails, getWithdrawRecent, cancelRequestService } from '../../API/Services/authService'
import ProfileStatus from '../../Components/ProfileStatusModal/ProfileStatus'
import { RouterConstant } from '../../constants/RouteConstant'
import LoaderWrapper from '../../Components/LoaderWrapper/LoaderWrapper'
import { getDateFromNo } from '../../helper/dateData'
import ErrorModal from '../../Components/ErrorModal/ErrorModal'
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import moment from 'moment/moment'


const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};

const HomePage = () => {
    let navigate = useNavigate()
    const [isOpen, setIsOpen] = useState(false)
    const [errorPopup, setErrorPopup] = useState(false)
    const [loader, setLoader] = useState(false);
    const [checkProfileStatus, setCheckProfileStatus] = useState(false);
    const [notUpdate, setNotUpdate] = useState('hide');
    const [customMessage, setCustomMessage] = useState('hide');
    const [user, setUser] = useState({})
    const [homeData, setHomeData] = useState({})
    const [withdrawalData, setWithdrawalData] = useState([])
    const [date, setDate] = useState('')
    const [requestId, setRequestId] = useState('')
    const [confirmModal, setConfirmModal] = useState(false)
    const handleToggleProfileStatus = () => {
        setIsOpen(!isOpen)
    }

    const handleErrorPopup = () => {
        setErrorPopup(!errorPopup)
    }

    useEffect(() => {
        let date = new Date();
        setDate(date)
    }, [])

    useEffect(() => {
        getProfileStatus()
            .then(response => {
                setLoader(false)
                if (response?.data?.status === 'OK') {
                    setCheckProfileStatus(response?.data?.data?.isAllDetailsVerified)
                }
                else {
                    console.log('Error response-->', response)
                }
            })
            .catch(error => {
                console.log(error)
                setLoader(false)
            })
    }, [])

    // check verification

    useEffect(() => {
        setLoader(true)
        getUserDetails()
            .then((response) => {
                setLoader(false)
                if (response?.data?.status === 'OK') {
                    setUser(response?.data?.data?.user)
                    if (
                        response?.data?.data?.user?.kycDetails?.status === 0 ||
                        response?.data?.data?.user?.bankDetails?.status === 0 ||
                        response?.data?.data?.user?.workDetails?.status === 0
                    ) {
                        setNotUpdate('show')
                        setCustomMessage('Your profile is not complete, Kindly complete the signup process')
                    } else {
                        setNotUpdate('hide')
                        setCustomMessage(response?.data?.message)
                    }
                }
                else {
                    console.log('Error response-->', response)
                }
            })
            .catch((error) => {
                setLoader(false)
                console.log('Error response-->', error)
            })
    }, [])

    // home dashboard info
    useEffect(() => {
        setLoader(true)
        gethomeDashboardInfo()
            .then((response) => {
                setLoader(false)
                if (response?.data?.status === 'OK') {
                    setHomeData(response?.data?.data?.homedata)
                }
                else {
                    console.log('Error response-->', response)
                }
            })
            .catch((error) => {
                setLoader(false)
                console.log('Error response-->', error)
            })
    }, [])


    const getWithdrawRecentList = () => {
        setLoader(true)
        getWithdrawRecent()
            .then(response => {
                setLoader(false)
                if (response?.data?.status === 'OK') {
                    setWithdrawalData(response?.data?.data?.withdrawalData)
                }
            })
            .catch(error => {
                setLoader(false)
                console.log("Error Withdraw=>", error)
            })
    }

    // winthdrawal recent
    useEffect(() => {
        getWithdrawRecentList();
    }, [])

    const redirectToRequest = () => {
        // if (notUpdate === "show") {
        navigate(RouterConstant.Request);
        // } else {
        //     handleErrorPopup()
        // }
    }


    const cancelRequest = () => {
        let params = {requestId}
        setConfirmModal(false);
        cancelRequestService(params)
            .then(response => {
                setRequestId('');
                if (response?.data?.status === 'OK') {
                    getWithdrawRecentList();
                }
            })
            .catch(error => {
                console.log("Error Withdraw=>", error)
            })

    }


    const handleCancelModal = (id) => {
        setRequestId(id);
        setConfirmModal(true)
    }


    return (
        <>

        <Modal
            isOpen={confirmModal}
            toggle={() => setConfirmModal(false)}
        >
            <ModalHeader toggle={() => setConfirmModal(false)} className='error-header'>
                Confirm
            </ModalHeader>
            <ModalBody className="error-body ">
                <p>Are you sure you want to cancel Request ?</p>
                <div className='btn_groups'>
                    <span onClick={() => setConfirmModal(false)} className='btn btn-dark d-inline'>No</span>
                    <span onClick={cancelRequest} className='btn btn-primary d-inline'>Yes</span>
                </div>
            </ModalBody>
        </Modal>


            {loader ? <LoaderWrapper /> :
                <div className="homepage-wrapper">
                    {/* banner section */}
                    <div className="home-inner-wrapper">
                        <div className="banner-wrapper">

                            <Slider {...settings}>
                                <div className="carousel-custom-slide">
                                    <h3 className="heading">You deserve to be paid 1</h3>
                                    <p className="text">Incash helps you to get paid <br />for your work when you really need it</p>
                                </div>
                                <div className="carousel-custom-slide">
                                    <h3 className="heading">You deserve to be paid 2</h3>
                                    <p className="text">Incash helps you to get paid <br />for your work when you really need it</p>
                                </div>
                                <div className="carousel-custom-slide">
                                    <h3 className="heading">You deserve to be paid 3</h3>
                                    <p className="text">Incash helps you to get paid <br />for your work when you really need it</p>
                                </div>
                            </Slider>
                        </div>
                        {/* banner section end*/}
                        <div className="home-card-wrapper-one">
                            <div className="card-one">
                                <div className="content">
                                    <p className="card-heading">Approved Limit</p>
                                    <p className="card-text">{`N ${homeData?.accountLimit?homeData?.accountLimit:'-'}`}</p>
                                </div>
                                <div className="content line-wrapper mb-0">
                                    <p className="card-heading">Monthly Salary</p>
                                    <p className="card-text">{`N ${homeData?.monthlySalary?homeData?.monthlySalary:'-'}`}</p>
                                </div>
                            </div>

                            <div className="home-card-wrapper-two cursor-pointer">
                                <div onClick={redirectToRequest} className="card-two">
                                    <div className="logo-icon-wrapper">
                                        <LogoIcon className="logo-light" />
                                    </div>
                                    <div className="card-background">
                                    </div>
                                    <div className="card-heading">
                                        Get Money Now
                                    </div>
                                    <div className="card-text">
                                        Pay a minimal interest on cash loan as low as N40/day
                                    </div>
                                </div>
                            </div>

                            <div className="card-one pd-3">
                                <div className="content line-wrapper">
                                    <p className="card-heading">Withdrawal Funds</p>
                                    <p className="card-text">{`N ${homeData?.withdrawnAmount==0||homeData?.withdrawnAmount?homeData?.withdrawnAmount:'-'}`}</p>
                                </div>
                                <div className="content mb-0">
                                    <p className="card-heading">Available Balance</p>
                                    <p className="card-text">{`N ${(homeData?.accountLimit - homeData?.withdrawnAmount)?(homeData?.accountLimit - homeData?.withdrawnAmount):'-'}`}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <aside className="recent-request">
                        <div className="request-header">
                            <h2 className="request-heading">Recent Requests</h2>
                            <Link to={RouterConstant.transaction} className="view-all">View All</Link>
                        </div>
                        <div className="recent-list-wrapper">
                            {
                                withdrawalData?.map((recent, index) => (
                                    <div className="resent-widrawal-card">
                                        <div className="widrawal-card-header">
                                            <div className="date">
                                                <label className="label">DATE</label>
                                                <h4 className="value">{moment(recent.createdAt).format('DD')} of {moment(recent.createdAt).format('MMM')}</h4>
                                            </div>
                                            <div className="status">
                                                <label className="label">STATUS</label>
                                                <h4 className={
                                                    `value ${recent?.status === 'pending' ? 'pending' : recent?.status === 'approved' ? 'approved' : recent?.status === 'advanceStatusLive' ? 'approved' : ''}`}>
                                                    {recent?.status === 'advanceStatusLive' ? 'Paid' : recent?.status}
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="recent-card-body">
                                            <div className="requested-amount">
                                                <label className="label">AMOUNT REQUESTED</label>
                                                <h4 className="value">{recent ? `N ${recent?.requestedAmount}` : 'N 0'}</h4>
                                            </div>
                                            {recent?.status == 'pending' ? <span onClick={() => handleCancelModal(recent._id)} className="request-action">Cancel Request</span> : ''}
                                            
                                        </div>
                                    </div>
                                ))

                            }

                            {
                                !withdrawalData.length ? <h2>No Data Found</h2> :''
                            }
                        </div>
                    </aside>

                    {/* complete profile */}
                    {/* {checkProfileStatus ? '' : <CompleteProfile
                        handleToggleProfileStatus={handleToggleProfileStatus}
                    />} */}

                </div>
            }

            <ProfileStatus
                isOpen={isOpen}
                toggle={handleToggleProfileStatus}
                data={user}
            />
            <ErrorModal
                isOpen={errorPopup}
                toggleError={handleErrorPopup}
                message={customMessage}
                isProfileComplete={notUpdate}
            />

            
        </>
    )
}

export default HomePage
