import React from 'react'
import { ReactComponent as WarnIcon } from '../../assets/images/icons/warning_icon.svg'
import { ReactComponent as RightArrowIcon } from '../../assets/images/icons/right_arrow_icon.svg'

function CompleteProfile({handleToggleProfileStatus}) {

    return (
        <>
        <div onClick={handleToggleProfileStatus} className="complete-profile">
            <div className="warning-msg-wrapper">
                <WarnIcon className="complete-profile-icon" />
                {/* <p className="warning-text">Complete your profile</p> */}
                {/* <RightArrowIcon className="complete-profile-icon ms-auto" /> */}
            </div>
        </div>
        {/* <NavLink to={RouterConstant.FillProfile} className="complete-profile">
            <div className="warning-msg-wrapper w-100">
                <WarnIcon className="complete-profile-icon" />
                <p className="warning-text">Complete your profile</p>
                <RightArrowIcon className="complete-profile-icon ms-auto" />
            </div>
        </NavLink> */}

    </>
    )
}

export default CompleteProfile
