import React, { useEffect } from 'react'
import './auth.scss';
import { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ReactComponent as Logo } from '../assets/images/logo.svg';
import './auth.scss';
import OtpInput from 'react-otp-input';
import { RouterConstant } from '../constants/RouteConstant';
import { otpApiFun, resendOtpFun } from '../API/Services/authService';
import { setToken, setUserId } from '../helper/helper';
import { useAlert } from 'react-alert';
import LoaderWrapper from '../Components/LoaderWrapper/LoaderWrapper';
import authBg from '../assets/images/background/auth-bg.png';

function AuthOtp() {
    // let phn = "9876543210"
    let navigate = useNavigate()
    const alert = useAlert();
    const [loader, setLoader] = useState(false);

    const { state } = useLocation()
    const { mobile, prevLink } = state
    const [otp, setOtp] = useState('')
    const [activeVerifyButton, setActiveVerifyButton] = useState(false)
    const [activeResendButton, setActiveResendButton] = useState(false);
    const [errorOtp, setErrorOtp] = useState(false)
    const [seconds, setSeconds] = useState(120);
    const tempOTP = localStorage.getItem('tempOtp')
    const {
        // register,
        handleSubmit,

    } = useForm();

    useEffect(() => {
        otpTimer()
    }, [seconds])
    // otp timer
    const otpTimer = () => {
        if (seconds > 0) {
            setActiveResendButton(false)
            setTimeout(() => setSeconds(seconds - 1), 1000)
        }
        else
            setActiveResendButton(true)
    }
    // check length of otp to activate submit button
    const handleOnChangeOtp = (otp) => {
        setOtp(otp)
        if (otp.length === 4)
            setActiveVerifyButton(true)
        else
            setActiveVerifyButton(false)
    }
    // handle otp
    const handleOtp = (formData) => {
        setLoader(true)
        let newPhone = mobile
        if (newPhone.length > 10) {
            newPhone = newPhone.substring(1)
        }
        let data = {
            "mobile": newPhone,
            "otp": otp
        }
        otpApiFun(data).then((response) => {
            setLoader(false)
            if (response?.data?.status === 'OK') {
                alert.success(response?.data?.message)
                localStorage.setItem('tempToken', response?.data?.data?.passwordSecret)
                // setToken(response?.data?.data?.passwordSecret)
                setUserId(response?.data?.data?.userId)
                if (prevLink === "forgotPassword")
                    navigate(RouterConstant.ResetPassword)
                else
                    navigate(RouterConstant.CreatePwd)
            }
        })
            .catch(error => {
                // setSeconds(0)
                console.log(error)
                setLoader(false)
                alert.error(error?.message)
                if (error?.message === "Invalid OTP")
                    setErrorOtp(true)
                else
                    console.log(error?.message)
            })
    }
    const handleResendOtp = () => {
        setLoader(true)
        let newPhone = mobile
        if (newPhone.length > 10) {
            newPhone = newPhone.substring(1)
        }

        let data = {
            "mobile": newPhone
        }
        resendOtpFun(data)
            .then((response) => {
                localStorage.setItem('tempOtp', response?.data?.data?.otp)
                setSeconds(10)
                alert.success(response?.data?.message)
                setLoader(false)
            })
            .catch(error => {
                setLoader(false)
                alert.error(error?.message)
                console.log(error)
            })
    }
    return (
        <>
            <div className="auth-wrapper" style={{backgroundImage: `url(${authBg})`}}>
                <div className="auth-inner-wrapper">
                    <div className="auth-header">
                        <div className="logo-wrapper">
                            <Logo className="logo-icon" />
                        </div>
                        <h2 className="auth-heading text-center">
                            Enter code sent to
                        </h2>
                        <p className="auth-sub-heading-1 text-center">+234 <span>{mobile}</span></p>
                        {/* <p className='text-center' style={{ opacity: 0.3 }}>Temp Otp: {tempOTP}</p> */}
                        <p className="auth-sub-heading-1 text-center sub-heading-modify">
                            <Link to={RouterConstant.Signup} className="heighlight">Wrong Number?</Link>
                        </p>
                    </div>
                    <form className="form-wrapper" onSubmit={handleSubmit(handleOtp)}>
                        <div className="input-wrapper">
                            <OtpInput
                                value={otp}
                                onChange={handleOnChangeOtp}
                                containerStyle="input-otp-wrapper"
                                inputStyle={`input-otp ${errorOtp ? "not-valid" : ""}`}
                                focusStyle="focus-style"
                                numInputs="4"
                                shouldAutoFocus="true"
                                isInputNum={true}
                            />
                            {errorOtp && <small className="error-msg text-center">You have entered a wrong verification code</small>}
                        </div>
                        <p className="otp-text">Didn’t receive OTP? Resend OTP in <b>{`${seconds}s`}</b></p>
                        {
                            activeResendButton ?
                                <div className="otp-resend-link">
                                    <button type="button" className="resend heighlight" onClick={handleResendOtp} disabled={!activeResendButton}>Resend</button>
                                </div> :
                                ''
                        }
                        <div className="text-center submit w-100">
                            <button type="submit" className="btn primary-btn w-100">Verify</button>
                        </div>
                    </form>
                </div>
            </div>
            {
                loader ?
                    <LoaderWrapper /> :
                    ''
            }
        </>
    )
}

export default AuthOtp
