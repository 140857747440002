export const companyNames = [{
    name: "Merald Technology Solutions Nigeria Ltd"
},
{
    name: "Samstar Nigeria Ltd"
},
{
    name: "Haulify"
},
{
    name: "Smridu Nigeria Ltd"
},
{
    name: "Meyar Integrated Services Nigeria Ltd"
},
{
    name: "Offshore FZE"
},
{
    name: "Karma Mart Limited"
},
{
    name: "Aptech Computer Education"
},
{
    name: "Incash Financial Services Nigeria Ltd",
},
{
    name: "Applied Engineering Technology Initiative (AETI)"
},
{
    name: "Pacegate Limited"
},
{
    name: "Sacvin Nigeria Limited"
},
{
    name: "Kewalram Chanrai Group"
},
{
    name: "Daleji Properties Ltd"
},
{
    name: "Africa Access 3PL",
},
{
    name: "African Industries Group"
}
]

export const dayDate = [
    { day: "1st of Every Month" }, 
    { day: "2nd of Every Month" }, 
    { day: "3rd of Every Month" }, 
    { day: "4th of Every Month" }, 
    { day: "5th of Every Month" }, 
    { day: "6th of Every Month" }, 
    { day: "7th of Every Month" }, 
    { day: "8th of Every Month" }, 
    { day: "9th of Every Month" }, 
    { day: "10th of Every Month" }, 
    { day: "11th of Every Month" }, 
    { day: "12th of Every Month" },
    { day: "13th of Every Month" }, 
    { day: "14th of Every Month" }, 
    { day: "15th of Every Month" }, 
    { day: "16th of Every Month" }, 
    { day: "17th of Every Month " }, 
    { day: "18th of Every Month" }, 
    { day: "19th of Every Month" }, 
    { day: "20th of Every Month" }, 
    { day: "21st of Every Month" }, 
    { day: "22nd of Every Month" }, 
    { day: "23rd of Every Month" }, 
    { day: "24th of Every Month" },
    { day: "25th of Every Month" }, 
    { day: "26th of Every Month" }, 
    { day: "27th of Every Month" }, 
    { day: "28th of Every Month" }, 
    { day: "29th of Every Month" }, 
    { day: "30th of Every Month" }, 
    { day: "31st of Every Month" },
]


export const banks = [
    {
        name: 'Citibank Nigeria Limited'
    },
    {
        name: 'Diamond Bank Plc'
    },
    {
        name: 'Ecobank Nigeria Plc'
    },
    {
        name: 'Fidelity Bank Plc'
    },
    {
        name: 'First Bank Of Nigeria LTD'
    },
    {    
        name: 'First City Monument Bank Plc'
    },
    {    
        name: 'Guaranty Trust Bank Plc'
    },
    {    
        name: 'Heritage Banking Company LTD'
    },
    {    
        name: 'Keystone Bank Limited'
    },
    {    
        name: 'Polaris Bank Limited'
    },
    {    
        name: 'Providus Bank Limited'
    },
    {    
        name: 'Stanbic IBTC Bank Ltd'
    },
    {    
        name: 'Standard Chartered Bank Nigeria Ltd'
    },
    {    
        name: 'Sterling Bank Plc'
    },
    {    
        name: 'SunTrust Bank Nigeria Limited'
    },
    {    
        name: 'Union Bank of Nigeria Plc'
    },
    {    
        name: 'United Bank For Africa Plc'
    },
    {    
        name: 'Unity Bank Plc'
    },
    {    
        name: 'Wema Bank Plc'
    },
    {    
        name: 'Zenith Bank Plc'
    },
    {    
        name: 'Kuda Bank'
    },
    {    
        name: 'Rubies Bank'
    },
    {    
        name: 'VFD MFB'
    },
    {    
        name: 'Mint Finex MFB'
    },
    {    
        name: 'Mkobo MFB'
    }
]