import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as IncashLogo } from './../../assets/images/referYourEmployer/Incash-refer-logo.svg';
import { ReactComponent as FooterLogo } from './../../assets/images/referYourEmployer/logo-footer.svg';
import { ReactComponent as Arrow } from './../../assets/images/referYourEmployer/arrow.svg';
import SuccessImage from './../../assets/images/referYourEmployer/success-image.png';
import { useAlert } from 'react-alert';
import './referEmployeer.scss';
import { addReferYourEmployer } from '../../API/Services/authService';
import { useForm } from 'react-hook-form';
import LoaderWrapper from '../../Components/LoaderWrapper/LoaderWrapper';

export default function ReferEmployerThankYou() {
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
    } = useForm();
    const alert = useAlert();
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])


    return (
        <div className='refer-page'>
            <div className='container-full-width'>
                <div className='container container-custom-xl'>
                    {/* <img src={}/> */}
                    <div className='header-refer-employer'>
                        <IncashLogo className="incash-logo" />
                    </div>
                </div>
                <div className='thanku-page'>
                    <h2 className='thanku-heading'>Thank You for Referring Your Employer.</h2>

                    <div className='container'>
                        <div className='row justify-content-center'>
                            <div className='col-lg-8'>
                                <div className='row justify-content-center'>
                                    <div className='col-lg-6'>
                                        <div className='img-thanku'>
                                            <img src={SuccessImage} className='thanku-image' />
                                        </div>
                                    </div>
                                </div>
                                <h3 className='what-next-heading'>What Next<span className='dot'>?</span></h3>

                                <p className='tanku-text'>- We're on it: Our team will reach out to your HR manager with the information you provided. We'll introduce our solution and explain how it can benefit your entire team.</p>
                                <p className='tanku-text'>- Stay Informed: While we work on connecting with your HR manager, feel free to explore our website for more details about our services and how they can improve your financial well-being.</p>

                                <a href='https://incash.africa/Employer.html' className='back-to-web'>Back to Home <Arrow className='arrow-icon' /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className='refer-footer'>
                <div className='container container-custom-xl'>
                    <div className='row justify-content-between'>
                        <div className='col-lg-5'>
                            <FooterLogo className='footer-logo' />
                            <p className='footer-text'>Incash is a fintech company that aims to revolutionize the delivery of financial services. We help employers to strategically manage their funds and ensure that all the employees get their right of financial freedom.</p>
                            <p className='footer-text copy-right d-none d-md-block'>© 2023 Incash Inc. All Rights Reserved</p>
                        </div>
                        <div className='col-lg-4 align-self-end'>
                            <div className='refer-social'>
                                <h3 className='Social'>Socials</h3>
                                <ul className='social-items list-unstyled'>
                                    <li className='social-link-wrapper'>
                                        <a target='_blank' href='https://www.linkedin.com/company/in-cash/' className='social-link'>LinkedIn</a>
                                    </li>
                                    <li className='social-link-wrapper'>
                                        <a target='_blank' href='https://youtube.com/@Incashafrica' className='social-link'>Youtube</a>
                                    </li>
                                    <li className='social-link-wrapper'>
                                        <a target='_blank' href='https://www.instagram.com/incashafrica/' className='social-link'>Instagram</a>
                                    </li>
                                    <li className='social-link-wrapper'>
                                        <a target='_blank' href='https://www.facebook.com/incash.africa/' className='social-link'>Facebook</a>
                                    </li>
                                    <li className='social-link-wrapper'>
                                        <a target='_blank' href='https://twitter.com/incashafrica?s=21&t=AbmpdOgXgcFkqUz9dupCTQ' className='social-link'>Twitter</a>
                                    </li>
                                </ul>
                            </div>

                            <p className='footer-text copy-right d-block d-md-none'>© 2023 Incash Inc. All Rights Reserved</p>
                        </div>
                    </div>
                </div>
            </div>
            {
                loader ?
                    <LoaderWrapper /> :
                    ''
            }
        </div>
    )
}
