import React, { useEffect, useState } from 'react'
import { set, useForm } from 'react-hook-form';
import Request from './Request';
import { ReactComponent as InformIcon } from '../../assets/images/icons/warning_icon.svg'
import './request.scss'
import { getUserDetails } from '../../API/Services/authService';
import ReviewRequest from './ReviewRequest';
import { useAlert } from 'react-alert';
import { processRequestAmount } from '../../API/Services/requestService';
import LoaderWrapper from '../../Components/LoaderWrapper/LoaderWrapper';
import ErrorModal from '../../Components/ErrorModal/ErrorModal';

const RequestMoney = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const [reviewRequest, setReviewRequest] = useState(false)
    const [interestAmount, setInterestAmount] = useState()
    const [isOpen, setIsOpen] = useState(false)

    const alert = useAlert();
    const [loader, setLoader] = useState(false);
    // const [isError, setIsError] = useState(false)
    const [message, setMessage] = useState({})


    // procecss fee info states
    // const [interestAmount, setInterestAmount] = useState()
    // const [requestedAmount, setRequestedAmount] = useState()
    // const [salaryDueDate, setSalaryDueDate] = useState()
    const [processFeedetails, setProcessFeedetails] = useState()
    // let amount = watch('amount', 0)
    // console.log('Amount===>',amount)
    const [user, setUser] = useState([])

    const [processingFee, setProcessingFee] = useState(0);

    useEffect(() => {
        getUser()
    }, [])


    const getUser = () => {
        setLoader(true)
        getUserDetails()
            .then((response) => {
                setLoader(false)
                if (response?.data?.status === 'OK') {
                    setUser(response?.data?.data?.user)
                }
                else {
                    console.log('Error response-->', response)
                }
            })
            .catch((error) => {
                setLoader(false)
                console.log('Error response-->', error)
            })
    }

    const handleToggleError = () => {
        setIsOpen(!isOpen)
    }

    const handleInterestAmount = (e) => {
        let amount = e.target.value;
        if (amount === 0 || amount === '' || amount === null) {
            setProcessingFee(0)
        } else if (amount > 0 && amount <= 25000) {
            setProcessingFee(500)
        } else if (amount > 25000 && amount <= 50000) {
            setProcessingFee(1000)
        } else if (amount > 50000 && amount <= 75000) {
            setProcessingFee(1500)
        } else if (amount > 75000 && amount <= 100000) {
            setProcessingFee(2000)
        } else {
            setProcessingFee(2500)
        }
    }

    const toggleReviewRequest = () => {
        setLoader(true)
        setTimeout(() => {
            setLoader(false)
            setReviewRequest(!reviewRequest)
        }, 500)
    }

    useEffect(() => {
        setLoader(true)
        setTimeout(() => {
            setLoader(false)
        }, 500)
    }, [])


    // handle request amount
    const handleRequest = (formData) => {
        setLoader(true)
        let data = "" + formData.amount
        // getUser()
        // let data = formData.amount
        if (user?.isSendForKYCApproval !== 4) {
            setLoader(false)
            setMessage('Your profile is not completed, please contact admin.')
            handleToggleError()
        }
        else {
            processRequestAmount(data)
                .then(response => {
                    console.log(response)
                    setLoader(false)
                    if (response?.data?.status === 'OK') {
                        alert.success(response?.data?.message)
                        // setInterestAmount(response?.data?.data?.processFeeInfo?.interestAmount)
                        // setRequestedAmount(response?.data?.data?.processFeeInfo?.requestedAmount)
                        // setSalaryDueDate(response?.data?.data?.processFeeInfo?.salaryDueDate)
                        setProcessFeedetails(response?.data?.data?.processFeeInfo)
                        setReviewRequest(true)
                    }
                    else {
                        console.log('Error response-->', response)
                    }
                })
                .catch(error => {
                    console.log(error?.isError)
                    setLoader(false)
                    // if(error?.isError){
                    //     setIsError(true)
                    // }
                    // setMessage(error?.message)
                    // alert.error(error?.message)
                    setMessage(error?.message)
                    handleToggleError()
                    // setIsError(true)
                    // setMessage(error?.message)
                })
        }
    }

    return (
        <div className="request-outer-wrapper">
            {
                loader ? <LoaderWrapper /> :
                    reviewRequest ?
                        <ReviewRequest
                            toggleReviewRequest={toggleReviewRequest}
                            interestAmount={processFeedetails?.interestAmount}
                            requestedAmount={processFeedetails?.requestedAmount}
                            salaryDueDate={processFeedetails?.salaryDueDate}
                        />
                        :
                        <div className="request-wrapper">
                            <h2 className="request-heading">How much do you want?</h2>
                            <p className="request-subheading">Please enter an amount</p>
                            <form className="form-wrapper" onSubmit={handleSubmit(handleRequest)}>
                                <div className="input-wrapper">
                                    <input type="number" className={`input-box form-control request-input ${errors.amount ? "not-valid" : ""}`}
                                        {...register('amount', { required: true })}
                                        id="amount" placeholder="N50,000"
                                        onChange={handleInterestAmount}
                                    />
                                    <div className="msg-wrapper">
                                        <InformIcon className="msg-icon" />
                                        <p className="msg-content ms-2">Processing fee {processingFee ? 'of N ' + processingFee : ''} will be charged</p>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-end'>
                                    <button type="submit" className="btn primary-btn min-padding">Proceed</button>
                                </div>
                            </form>
                        </div>
            }
            <ErrorModal
                isOpen={isOpen}
                toggleError={handleToggleError}
                message={message}
            />
        </div>
    )
}
export default RequestMoney
