import React, { useState } from 'react'
import Footer from '../Template/Footer'
import Header from '../Template/Header'
import Sidebar from '../Template/Sidebar'
import './layout.scss'

import { Outlet } from 'react-router-dom'


const Layout = () => {
    const [toggle, setToggle] = useState(false);
    const [background, setBackground] = useState(false);
    const toggleSidebar = () => {
        setToggle(!toggle)
        setTimeout(() => {
            setBackground(!background)
        }, 170)
    }
    return (
        <div className="main-wrapper d-flex">
            <Sidebar
                toggle={toggle}
                background={background}
                toggleSidebar={toggleSidebar}
            />
            <Header
                toggle={toggle}
                toggleSidebar={toggleSidebar}
            />
            <div className="content-outer-wrapper">
                <div className="content-wrapper">
                    {/* <Routes/> */}
                    <Outlet />
                    {/* {children} */}
                </div>
            </div>
        </div>
    )
}

export default Layout
